import React from 'react';
import CustomState from './CustomState';
import { baseStatePropTypes } from './baseStatePropTypes';

const ErrorState = (props) => <CustomState {...props} />;

ErrorState.defaultProps = {
    icon: 'rioglyph-truck-breakdown',
};

ErrorState.propTypes = baseStatePropTypes;

export default ErrorState;
