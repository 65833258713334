import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import orderBy from 'lodash/fp/orderBy';
import isObject from 'lodash/fp/isObject';
import debounce from 'lodash/fp/debounce';
import cond from 'lodash/fp/cond';
import without from 'lodash/fp/without';
import countBy from 'lodash/fp/countBy';
import getOr from 'lodash/fp/getOr';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import size from 'lodash/fp/size';

import TreeSearch from './TreeSearch';
import TreeSelectAll from './TreeSelectAll';
import TreeSummary from './TreeSummary';
import TreeNode from './TreeNode';
import TreeLeaf from './TreeLeaf';
import TreeNothingFound from './TreeNothingFound';

const SEARCH_DEBOUNCE = 100;

const otherwise = () => true;

const isNameObject = item => isObject(item.name);
const getFullName = item => `${item.name.firstName} ${item.name.lastName}`;
const getName = item => (isNameObject(item) ? getFullName(item) : item.name);

const getLowerCaseName = item => getOr('', 'name')(item).toLowerCase();
const getLowerCaseLastName = item => getOr('', 'name.lastName')(item).toLowerCase();

const orderByName = orderBy(
    [item => (isNameObject(item) ? getLowerCaseLastName(item) : getLowerCaseName(item))],
    ['asc']
);

const filterByName = searchValue => item => {
    if (searchValue) {
        return getName(item)
            .toLowerCase()
            .includes(searchValue.toLowerCase());
    }
    return true;
};

const getAssetTypeCounts = items => countBy(item => item.type, items);

class Tree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groupedItems: [],
            flatItems: [],
            searchValue: '',
            allChecked: false,
            groups: props.groups,
            items: props.items,
            searchvalue: props.searchvalue,
            assetCounts: {},
        };

        this.handleToggleNode = this.handleToggleNode.bind(this);
        this.handeSelectAll = this.handeSelectAll.bind(this);
        this.handleGroupSelection = this.handleGroupSelection.bind(this);
        this.handleItemSelection = this.handleItemSelection.bind(this);
        this.handleSetItemActive = this.handleSetItemActive.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleUpdateTreeDeferred = debounce(SEARCH_DEBOUNCE, this.handleUpdateTreeDeferred.bind(this));
        this.hasSearchAndGroups = this.hasSearchAndGroups.bind(this);
        this.hasNoSearchAndGroups = this.hasNoSearchAndGroups.bind(this);
    }

    componentDidMount() {
        this.makeTree();

        this.setState(() => ({
            allChecked: this.checkAllSelected(this.props, this.state),
            assetCounts: getAssetTypeCounts(this.props.items),
        }));
    }

    componentWillReceiveProps(nextProps) {
        const { selectedItems, selectedGroups, items, groups, searchValue } = this.props;
        const {
            selectedItems: nextSelectedItems,
            selectedGroups: nextSelectedGroups,
            items: nextItems,
            groups: nextGroups,
            searchValue: nextSearchValue,
        } = nextProps;

        // Update Tree when items or groups have changed
        if (!isEqual(items, nextItems) || !isEqual(groups, nextGroups) || !isEqual(searchValue, nextSearchValue)) {
            this.setState(() => ({
                items: nextItems,
                groups: nextGroups,
                assetCounts: getAssetTypeCounts(nextItems),
            }));
            this.makeTree(nextGroups, nextItems);
        }

        if (!isEqual(selectedItems, nextSelectedItems) || !isEqual(selectedGroups, nextSelectedGroups)) {
            this.setState(() => ({
                allChecked: this.checkAllSelected(nextProps, this.state),
            }));
        }
    }

    checkAllSelected(props, state = { flatItems: [] }) {
        const { selectedItems, items, groups, selectedGroups } = props;
        const { flatItems } = state;

        if (
            (!this.hasGroups() && isEmpty(selectedItems)) ||
            (this.hasNoSearchAndGroups() && isEmpty(selectedGroups)) ||
            (this.hasSearchAndGroups() && isEmpty(selectedItems))
        ) {
            return false;
        }

        if (this.hasNoSearchAndGroups()) {
            const unselectedGroups = groups.filter(group => !selectedGroups.includes(group.id));
            return isEmpty(unselectedGroups);
        } else if (this.hasSearchAndGroups()) {
            const unselectedSearchItems = flatItems.filter(item => !selectedItems.includes(item.id));
            return isEmpty(unselectedSearchItems);
        }

        const unselectedItems = items.filter(item => !selectedItems.includes(item.id));
        return isEmpty(unselectedItems);
    }

    getMappedItemsToGroups(groups, items) {
        const mappedGroups = {};

        // build an object for listing the groups by id
        groups.forEach(group => {
            mappedGroups[group.id] = {
                ...group,
                items: [],
            };
        });

        items.forEach(item => {
            // add items to the respective group
            const groupIds = item.groupIds || [];
            groupIds.forEach(groupId => {
                const mappedGroup = mappedGroups[groupId];
                if (mappedGroup) {
                    mappedGroup.items.push(item);
                }
            });
        });

        const sortedGroups = this.sortGroupItemsByName(mappedGroups);

        const result = this.sortGroupsByName(sortedGroups);

        return result;
    }

    sortGroupsByName(groups) {
        const fixedGroups = {};
        const sortableGroups = {};

        map(group => {
            if (group.position === 'last') {
                fixedGroups[group.id] = { ...group };
            } else {
                sortableGroups[group.id] = { ...group };
            }
        })(groups);

        const sortedGroups = orderByName(sortableGroups);

        return isEmpty(fixedGroups) ? sortedGroups : { ...sortedGroups, ...fixedGroups };
    }

    sortGroupItemsByName(groups) {
        const sortedGroups = {};
        map(group => {
            sortedGroups[group.id] = {
                ...group,
                items: orderByName(group.items),
            };
        })(groups);
        return sortedGroups;
    }

    getFlatItems(items, searchValue) {
        return flow(filter(filterByName(searchValue)), orderByName)(items);
    }

    handleToggleNode(nodeId) {
        const { onExpandGroupsChange, expandedGroups } = this.props;

        const newExpandedNodes = expandedGroups.includes(nodeId)
            ? expandedGroups.filter(item => item !== nodeId)
            : [...expandedGroups, nodeId];

        // Performance improvement to skip on render cycle and change "open" class directly
        if (expandedGroups.includes(nodeId)) {
            this.getNodeContainerDomElementById(nodeId).classList.remove('open');
        } else {
            this.getNodeContainerDomElementById(nodeId).classList.add('open');
        }

        onExpandGroupsChange(newExpandedNodes);
    }

    getNodeContainerDomElementById(nodeId) {
        return document.querySelector(`.TreeNodeContainer[data-id="${nodeId}"]`);
    }

    handeSelectAll(shouldSelect, isIndeterminate) {
        const shouldSelectAll = shouldSelect && !isIndeterminate;

        cond([
            [this.hasNoSearchAndGroups, () => this.selectAllGroups(shouldSelectAll)],
            [this.hasSearchAndGroups, () => this.selectAllSearchResultItems(shouldSelectAll)],
            [otherwise, () => this.selectAllFlatItems(shouldSelectAll)],
        ])();

        this.setState(() => ({
            allChecked: shouldSelectAll,
        }));
    }

    getListIds(list) {
        return list.map(listItem => listItem.id);
    }

    selectAllGroups(shouldSelect) {
        const { groups } = this.props;
        const newSelectedGroupIds = shouldSelect ? this.getListIds(groups) : [];
        this.respondSelection([], newSelectedGroupIds);
    }

    selectAllSearchResultItems(shouldSelect) {
        this.selectAllFlatItems(shouldSelect);
    }

    selectAllFlatItems(shouldSelect) {
        const { flatItems } = this.state;

        const newSelectedIds = shouldSelect ? this.getListIds(flatItems) : [];

        this.respondSelection(newSelectedIds, []);
    }

    respondSelection(selectedItems, selectedGroups) {
        const { onSelectionChange } = this.props;
        onSelectionChange({ items: selectedItems, groups: selectedGroups });
    }

    handleGroupSelection(group, isIndeterminate) {
        const { selectedGroups, selectedItems } = this.props;
        const { groupedItems } = this.state;

        const groupId = group.id;

        const isSelected = selectedGroups.includes(groupId);
        const shouldSelectGroup = !isSelected && !isIndeterminate;

        // handle group selection
        const newSelectedGroups = shouldSelectGroup
            ? [...selectedGroups, groupId]
            : this.excludeFromList(selectedGroups, groupId);

        // deselect all items of a node since they will be selected inherently via the group itself
        const itemsInGroup = find(entry => entry.id === groupId)(groupedItems);
        const itemIdsOfGroup = itemsInGroup.items.map(item => item.id);
        const updatedSelectedItems = without(itemIdsOfGroup, selectedItems);

        this.respondSelection(updatedSelectedItems, newSelectedGroups);
    }

    handleItemSelection(itemId) {
        const { selectedItems, selectedGroups } = this.props;
        const isSelected = selectedItems.includes(itemId);
        const newSelectedItems = isSelected ? this.excludeFromList(selectedItems, itemId) : [...selectedItems, itemId];

        this.respondSelection(newSelectedItems, selectedGroups);
    }

    handleSetItemActive(itemId) {
        const newSelectedItems = [itemId];
        this.respondSelection(newSelectedItems, []);
    }

    excludeFromList(list, itemId) {
        return list.filter(item => item !== itemId);
    }

    handleSearchChange(updatedSearchValue) {
        const { searchValue, onSearchChange } = this.props;

        onSearchChange(updatedSearchValue);

        if (searchValue) {
            this.setState(() => ({
                allChecked: false,
            }));
            return;
        }

        this.setState(() => ({
            searchValue: updatedSearchValue,
            allChecked: false,
        }));
        this.handleUpdateTreeDeferred();
    }

    handleUpdateTreeDeferred() {
        this.makeTree();
    }

    hasSearchAndGroups() {
        return this.hasSearch() && this.hasGroups();
    }

    hasNoSearchAndGroups() {
        return !this.hasSearch() && this.hasGroups();
    }

    makeTree(updatedGroups, updatedItems) {
        const { groups, items, searchValue } = this.state;

        const groupsToProcess = updatedGroups || groups;
        const itemsToProcess = updatedItems || items;

        const hasGroups = groupList => groupList && !isEmpty(groupList);

        const hasNoSearchAndGroups = () => isEmpty(searchValue) && hasGroups(groupsToProcess);
        const hasSearchAndGroups = () => !isEmpty(searchValue) && hasGroups(groupsToProcess);

        const setGroupedItems = () => this.setGroupedItems(groupsToProcess, itemsToProcess);
        const setFlatItemList = () => this.setFlatItemList(itemsToProcess, searchValue);

        cond([
            [hasNoSearchAndGroups, setGroupedItems],
            [hasSearchAndGroups, setFlatItemList],
            [otherwise, setFlatItemList],
        ])();
    }

    setFlatItemList(items, searchValue) {
        const flatItems = this.getFlatItems(items, searchValue);
        this.setState(() => ({
            flatItems,
            assetCounts: getAssetTypeCounts(flatItems),
        }));
    }

    setGroupedItems(groups, items) {
        // Map items to groups with filtered items
        const newGroupedItems = this.getMappedItemsToGroups(groups, items);
        //const itemList = flatMap(group => group.items)(newGroupedItems);

        this.setState(() => ({
            groupedItems: newGroupedItems,
            assetCounts: getAssetTypeCounts(items),
        }));
    }

    renderTree() {
        const { hasMultiselect, selectedGroups, selectedItems, expandedGroups } = this.props;
        const { groupedItems } = this.state;

        if (isEmpty(groupedItems)) {
            return <TreeNothingFound />;
        }

        const result = map(group => {
            const isOpen = expandedGroups.includes(group.id);
            const nodeContainerClassNames = classNames('TreeNodeContainer', isOpen && 'open');

            const numSelectedGroupItems = group.items.filter(item => selectedItems.includes(item.id)).length;

            const isSelected = selectedGroups.includes(group.id);
            const isIndeterminate = !isSelected && numSelectedGroupItems > 0;

            const leafs = group.items.map(item => (
                <TreeLeaf
                    key={item.id}
                    item={item}
                    parentNodeId={group.id}
                    hasMultiselect={hasMultiselect}
                    isSelected={selectedItems.includes(item.id)}
                    onSelect={this.handleItemSelection}
                    onActiveItem={this.handleSetItemActive}
                />
            ));

            return (
                <div key={group.id} className={nodeContainerClassNames} data-id={group.id}>
                    <TreeNode
                        node={group}
                        hasMultiselect={hasMultiselect}
                        onToggleNode={this.handleToggleNode}
                        onSelect={this.handleGroupSelection}
                        isSelected={isSelected}
                        isIndeterminate={isIndeterminate}
                    />
                    {leafs}
                    <div className={`TreeLeafs ${isOpen ? 'open' : 'position-offscreen'}`}></div>
                </div>
            );
        })(groupedItems);
        return result;
    }

    renderFlatList() {
        const { hasMultiselect, selectedItems } = this.props;
        const { flatItems } = this.state;

        return (
            <div className={'TreeNodeContainer open'}>
                {isEmpty(flatItems) ? (
                    <TreeNothingFound />
                ) : (
                    flatItems.map(item => {
                        const isSelected = selectedItems.includes(item.id);
                        return (
                            <TreeLeaf
                                key={item.id}
                                item={item}
                                hasMultiselect={hasMultiselect}
                                isSelected={isSelected}
                                onSelect={this.handleItemSelection}
                                onActiveItem={this.handleSetItemActive}
                            />
                        );
                    })
                )}
            </div>
        );
    }

    hasGroups() {
        const { groups } = this.state;
        return groups && !isEmpty(groups);
    }

    hasSearch() {
        const { searchValue } = this.state;
        return !isEmpty(searchValue);
    }

    render() {
        const {
            hideSearch,
            searchPlaceholder,
            hasMultiselect,
            scrollHeight,
            search,
            summary,
            hideSummary,
            searchValue: externalSearchValue,
            className,
            groups,
            selectedGroups,
            selectedItems,
        } = this.props;
        const { searchValue, allChecked, flatItems, groupedItems, assetCounts } = this.state;

        const treeClassNames = classNames('Tree', className);

        const shouldRenderTree = this.hasGroups() && !this.hasSearch();

        const treeRootClasses = classNames('TreeRoot', 'user-select-none');

        const maxScrollHeight = {
            maxHeight: scrollHeight,
        };

        const hasGroups = !isEmpty(groups);
        const hasSearch = !isEmpty(searchValue) || !isEmpty(externalSearchValue);

        const hasSearchAndNoItems = hasSearch && isEmpty(flatItems);
        const hasSearchAndNoGroups = hasSearch && isEmpty(groupedItems) && hasGroups;
        const hideSelectAll = hasSearchAndNoItems || hasSearchAndNoGroups;

        const hasSelectedAllGroups = isEqual(size(selectedGroups), size(groups));
        const hasPartialySelectedGroups = hasGroups && !isEmpty(selectedGroups) && !hasSelectedAllGroups;

        const hasSelectedAllItems = isEqual(size(selectedItems), size(flatItems));
        const hasPartialySelectedItems = !isEmpty(selectedItems) && !hasSelectedAllItems;

        const isIndeterminate = hasPartialySelectedGroups || hasPartialySelectedItems;

        const summaryComponent = summary ? summary : <TreeSummary assetCounts={assetCounts} />;

        const treeHeadClasses = classNames(
            'TreeHead',
            'display-flex align-items-center',
            !hasMultiselect && hideSummary ? '' : 'padding-15'
        );

        return (
            <div className={treeClassNames}>
                <div className={'TreeHeader'}>
                    {!hideSearch && !search && (
                        <TreeSearch
                            value={searchValue}
                            onChange={this.handleSearchChange}
                            placeholder={searchPlaceholder}
                        />
                    )}
                    {search && search}
                    <div className={treeHeadClasses}>
                        {!hideSelectAll && (
                            <TreeSelectAll
                                isChecked={allChecked}
                                isEnabled={hasMultiselect}
                                isIndeterminate={isIndeterminate}
                                onSelect={this.handeSelectAll}
                            />
                        )}
                        {!hideSummary && summaryComponent}
                    </div>
                </div>
                <div className={treeRootClasses} style={maxScrollHeight}>
                    {shouldRenderTree ? this.renderTree() : this.renderFlatList()}
                </div>
            </div>
        );
    }
}

Tree.displayName = 'Tree';

Tree.defaultProps = {
    hasMultiselect: true,
    hideSearch: false,
    hideSummary: false,
    selectedGroups: [],
    selectedItems: [],
    onSelectionChange: () => {},
    onExpandGroupsChange: () => {},
    onSearchChange: () => {},
    searchPlaceholder: 'Type here to filter by name',
    groups: [],
};

Tree.propTypes = {
    groups: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            icon: PropTypes.string,
            className: PropTypes.string,
        })
    ),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    firstName: PropTypes.string,
                    lastName: PropTypes.string.isRequired,
                }),
            ]).isRequired,
            type: PropTypes.string.isRequired,
            groupIds: PropTypes.arrayOf(PropTypes.string),
            className: PropTypes.string,
        })
    ),
    selectedGroups: PropTypes.arrayOf(PropTypes.string),
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    onSelectionChange: PropTypes.func,
    hasMultiselect: PropTypes.bool,
    hideSearch: PropTypes.bool,
    summary: PropTypes.node,
    hideSummary: PropTypes.bool,
    search: PropTypes.node,
    searchValue: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    onSearchChange: PropTypes.func,
    className: PropTypes.string,
    scrollHeight: PropTypes.number,
};

export default Tree;
