import React from 'react';
import classNames from 'classnames';

const StatsWidgetBody = props => {
    const { children, className, ...remainingProps } = props;
    const classes = classNames('StatsWidget-body', className);

    return (
        <div {...remainingProps} className={classes}>
            {children}
        </div>
    );
};

export default StatsWidgetBody;
