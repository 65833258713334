import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutBodyBanner = props => {
    const { textColor, backgroundColor, isSticky, className, children, ...remainingProps } = props;

    const bannerClasses = classNames(
        'ApplicationLayoutBodyBanner',
        textColor,
        backgroundColor,
        isSticky && 'position-sticky top-0 z-index-3',
        className
    );

    return (
        <div {...remainingProps} className={bannerClasses}>
            {children}
        </div>
    );
};

ApplicationLayoutBodyBanner.defaultProps = {
    textColor: 'text-color-white',
    backgroundColor: 'bg-primary',
    isSticky: true,
};

ApplicationLayoutBodyBanner.propTypes = {
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    isSticky: PropTypes.bool,
    className: PropTypes.string,
};

export default ApplicationLayoutBodyBanner;
