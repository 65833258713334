import React from 'react';
import CustomState from './CustomState';
import { baseStatePropTypes } from './baseStatePropTypes';

const NotFoundState = (props) => <CustomState {...props} />;

NotFoundState.defaultProps = {
    icon: 'rioglyph-looking-glass-man',
};

NotFoundState.propTypes = baseStatePropTypes;

export default NotFoundState;
