import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StatsWidgetNumber = props => {
    const { value, total, label, className, clickable, ...remainingProps } = props;

    const classes = classNames('StatsWidgetNumber', clickable && 'cursor-pointer', className);

    return (
        <div {...remainingProps} className={classes}>
            <div className={'StatsWidgetNumber-counter'}>
                <div className={'StatsWidgetNumber-value'}>{value}</div>
                {total && <div className={'StatsWidgetNumber-total'}>{total}</div>}
            </div>
            {label && <div className={'StatsWidgetNumber-label'}>{label}</div>}
        </div>
    );
};

StatsWidgetNumber.defaultProps = {
    value: 0,
    clickable: false,
    onClick: () => {},
};

StatsWidgetNumber.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    clickable: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default StatsWidgetNumber;
