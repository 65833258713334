import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutSidebar = ({ className, children }) => {
    const classes = classNames('ApplicationLayoutSidebar', className);

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

ApplicationLayoutSidebar.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayoutSidebar;
