import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Switch extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChange(event.target.checked);
    }

    render() {
        const { keyName, checked, enabledText, minWidth, disabled, disabledText, labelPosition, children } = this.props;
        const hasMultipleText = enabledText && disabledText;
        const hasSingleText = enabledText && !disabledText;

        const switchLabelClasses = classNames(
            'switch-label',
            disabled && 'cursor-not-allowed',
            labelPosition && `label-position-${labelPosition}`
        );

        const switchContentClasses = classNames(
            'switch-content'
            //!hasMultipleText && !hasSingleText && 'width-40'
        );

        const switchContentStyles = {
            minWidth,
        };

        return (
            <div className={'uikit-switch'}>
                <label className={switchLabelClasses}>
                    <input
                        onChange={this.handleChange}
                        checked={checked}
                        type={'checkbox'}
                        id={keyName}
                        key={keyName}
                        className={'switch-input'}
                        disabled={disabled}
                    />
                    <div className={switchContentClasses} style={switchContentStyles}>
                        {hasMultipleText ? (
                            <div className={'switch-text'} data-on={enabledText} data-off={disabledText} />
                        ) : (
                            hasSingleText && (
                                <div className={'switch-text'} data-on={enabledText} data-off={enabledText} />
                            )
                        )}
                        <div className={'switch-handle'} />
                    </div>
                    {children && children}
                </label>
            </div>
        );
    }
}

Switch.LABEL_POSITION_LEFT = 'left';
Switch.LABEL_POSITION_RIGHT = 'right';

Switch.defaultProps = {
    onChange: () => {},
    keyName: '',
    checked: false,
    minWidth: 40,
    disabled: false,
};

Switch.propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    keyName: PropTypes.string,
    minWidth: PropTypes.number,
    disabled: PropTypes.bool,
    enabledText: PropTypes.string,
    disabledText: PropTypes.string,
    labelPosition: PropTypes.oneOf([Switch.LABEL_POSITION_LEFT, Switch.LABEL_POSITION_RIGHT]),
};
