import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Transition, { ENTERED, ENTERING } from 'react-transition-group/Transition';

const fadeStyles = {
    [ENTERING]: 'in',
    [ENTERED]: 'in',
};

// reading a dimension prop will cause the browser to recalculate,
// which will let our animations work
function triggerBrowserReflow(node) {
    node.offsetHeight;
}

class Fade extends React.Component {

    constructor(props) {
        super(props);
        this.handleEnter = this.handleEnter.bind(this);
    }

    handleEnter(node) {
        triggerBrowserReflow(node);
        if (this.props.onEnter) {
            this.props.onEnter(node);
        }
    }

    render() {
        const { className, children, ...props } = this.props;
        return (
            <Transition {...props} onEnter={this.handleEnter}>
                {(status, innerProps) => React.cloneElement(children, {
                    ...innerProps,
                    className: classNames(
                        'fade',
                        className,
                        children.props.className,
                        fadeStyles[status]
                    ),
                })}
            </Transition>
        );
    }
}

Fade.defaultProps = {
    in: false,
    timeout: 200,
    mountOnEnter: false,
    unmountOnExit: false,
    appear: false,
};

Fade.propTypes = {
    in: PropTypes.bool,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    appear: PropTypes.bool,
    timeout: PropTypes.number,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
};

export default Fade;
