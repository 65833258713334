import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ResponsiveVideo = (props) => {
    const { src, className, allowFullScreen, aspectRatio } = props;

    const wrapperClassName = classNames(
        'video-responsive',
        `video-responsive-${aspectRatio}`,
        className && className
    );

    return (
        <div className={wrapperClassName}>
            <iframe src={src} allowFullScreen={allowFullScreen}></iframe>
        </div>
    );
};

ResponsiveVideo.ASPECT_RATIO_4_BY_3 = '4by3';
ResponsiveVideo.ASPECT_RATIO_16_BY_9 = '16by9';

ResponsiveVideo.defaultProps = {
    aspectRatio: ResponsiveVideo.ASPECT_RATIO_16_BY_9,
    allowFullScreen: true,
};

ResponsiveVideo.propTypes = {
    src: PropTypes.string.isRequired,
    aspectRatio: PropTypes.oneOf([
        ResponsiveVideo.ASPECT_RATIO_4_BY_3,
        ResponsiveVideo.ASPECT_RATIO_16_BY_9,
    ]),
    allowFullScreen: PropTypes.bool,
};

export default ResponsiveVideo;
