import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const TagList = (props) => {
    const { children, inline, autoTagWidth, tagsPerRow, ...remainingProps } = props;

    const wrappedChidren = (
        <div className={`tags-per-row-${tagsPerRow}`}>
            {children}
        </div>
    );

    return (
        <div
            {...remainingProps}
            className={
                classname(
                    'taglist',
                    !autoTagWidth && 'taglist-autowidth',
                    !inline && 'taglist-vertical',
                    tagsPerRow && 'row display-flex-sm flex-wrap-sm',
                    props.className
                )
            }
        >
            {tagsPerRow ? wrappedChidren : children}
        </div>
    );
};

TagList.defaultProps = {
    inline: true,
    autoTagWidth: false,
};

TagList.propTypes = {
    inline: PropTypes.bool,
    autoTagWidth: PropTypes.bool,
    tagsPerRow: PropTypes.PropTypes.oneOf([1, 2, 3, 4, 6]),
    className: PropTypes.string,
};

export default TagList;
