import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClearableInput from '../clearableInput/ClearableInput';

const TableSearch = (props) => {
    const { className } = props;

    const wrapperClassNames = classNames(
        'table-toolbar-search',
        'input-group',
        className && className
    );

    return (
        <div className={wrapperClassNames}>
            <span className='input-group-addon'>
                <span className='rioglyph rioglyph-search'></span>
            </span>
            <ClearableInput {...props} />
        </div>
    );
};

TableSearch.defaultProps = {};

TableSearch.propTypes = {
    className: PropTypes.string,
};

export default TableSearch;
