import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppMenuDropdown from './AppMenuDropdown';
import modulePropTypes from './modulePropTypes';

export class AppMenu extends Component {

    renderAppMenu() {
        const { appMenuItems, appNavigator } = this.props;

        // In case there is a navigation component injected into the header,
        // render this component
        if (appNavigator) {
            return [<li role='presentation' key='app-navigator'>{appNavigator}</li>];
        }

        // If there is a list of appMenuItems given, render them as a list
        return appMenuItems.map((module) => {
            return (
                <li role='presentation' key={`link-icon-${module.key}`}>
                    {module.route}
                </li>
            );
        });
    }

    render() {
        const { label = 'Unknown' } = this.props;

        const appMenu = this.renderAppMenu();
        const hasModulesClass = (this.props.appMenuItems.length || this.props.appNavigator) ? 'hasModules' : '';

        return (
            <ul className={`ModuleNavigation AppMenu ${hasModulesClass} user-select-none`}>
                <AppMenuDropdown title={label} caret={!!appMenu.length}>
                    {appMenu}
                </AppMenuDropdown>
            </ul>
        );
    }
}

AppMenu.defaultProps = {
    appMenuItems: [],
};

AppMenu.propTypes = {
    appMenuItems: PropTypes.arrayOf(modulePropTypes),
    appNavigator: PropTypes.element,
};
