import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../tag/Tag';

const SelectedFilterTag = (props) => {
    const { title, labels, onDelete, keyName, className } = props;

    const tags = labels.map((label, index) => {
        return (
            <li className={`SelectedFilterTag ${className && className}`} key={`${keyName}-${index}`}
                onClick={() => {
                    onDelete(label);
                }}>
                <Tag size='small' deletable>
                    {title && title}
                    {title && ': '}
                    {label}
                </Tag>
            </li>
        );
    });

    return (
        <li>
            <ul className='list-inline'>
                {tags}
            </ul>
        </li>
    );
};

SelectedFilterTag.defaultProps = {
    onDelete: () => {},
};

SelectedFilterTag.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    labels: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ])),
    onDelete: PropTypes.func,
    keyName: PropTypes.string,
    className: PropTypes.string,
};

export default SelectedFilterTag;
