import React from 'react';

export const ApplicationActionBar = ({ items, nodeRef }) => (
    <ul className='ApplicationActionBar nav navbar-nav navbar-right' ref={nodeRef}>
        {items && items.map((item, index) => {
            return (
                <li key={index} role='presentation' className='navigationItem'>
                    {item}
                </li>
            );
        })}
    </ul>
);
