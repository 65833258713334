import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

/*
 * Component to show a modal popup containing a title, a content and a close handler.
 */
const SimpleDialog = (props) => {
    const {
        show,
        title,
        subtitle,
        content,
        onClose,
        className,
        bsSize,
        disableEsc,
    } = props;

    const dialogClassName = `${className ? className : ''} simple-dialog`;

    return (
        <Dialog
            show={show}
            title={title}
            subtitle={subtitle}
            body={content}
            onHide={onClose}
            className={dialogClassName}
            showCloseButton={true}
            bsSize={bsSize}
            disableEsc={disableEsc}
        />
    );
};

SimpleDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    content: PropTypes.node,
    onClose: PropTypes.func,
    className: PropTypes.string,
    bsSize: PropTypes.string,
    disableEsc: PropTypes.bool,
};

export default SimpleDialog;
