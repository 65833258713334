// It requires the DOM node for the toggle (e.g. the dropdown button) and the DOM element
// that shall be used to calculate remaining space (i.e. the dropdown menu)
export default function getDropDirection(toggleNode, menuNode) {
    if (!toggleNode || !menuNode) {
        return {};
    }

    const clientRect = toggleNode.getBoundingClientRect();
    const pageX = clientRect.x;
    const pageY = clientRect.y;

    const toggleHeight = toggleNode.offsetHeight;

    // menu has its height and width already when closed because visibility is collapse
    const menuWidth = menuNode.offsetWidth;
    const menuHeight = menuNode.offsetHeight;

    const spaceUp = pageY - toggleHeight - menuHeight - window.pageYOffset;
    const spaceDown = window.innerHeight - (pageY + menuHeight + toggleHeight);

    const spaceLeft = pageX - menuWidth - window.pageXOffset;
    const spaceRight = window.innerWidth - (pageX + menuWidth);

    const dropup = (spaceDown < 0) && ((spaceUp >= 0) || (spaceUp > spaceDown));
    const pullRight = (spaceRight < 0) && ((spaceLeft >= 0) || (spaceLeft > spaceRight));

    return ({
        dropup: dropup,
        pullRight: pullRight,
    });
}
