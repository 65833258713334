import React from 'react';
import CustomState from './CustomState';
import { baseStatePropTypes } from './baseStatePropTypes';

const ForbiddenState = (props) => <CustomState {...props} />;

ForbiddenState.defaultProps = {
    icon: 'rioglyph-ban-circle',
};

ForbiddenState.propTypes = baseStatePropTypes;

export default ForbiddenState;
