import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// TODO: add responsive behaviour and trigger a change from table to list for small screen -> see DriverAdministration

class TableViewToggles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            viewType: this.props.initialViewType,
        };

        this.handleSetTableView = this.handleSetTableView.bind(this);
        this.handleSetSingleCardsView = this.handleSetSingleCardsView.bind(this);
        this.handleSetMultiCardsView = this.handleSetMultiCardsView.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.viewType !== nextProps.viewType) {
            this.setState({
                viewType: nextProps.viewType,
            });
        }
    }

    handleSetTableView() {
        this.setState({ viewType: TableViewToggles.VIEW_TYPE_TABLE });
        this.props.onViewTypeChange(TableViewToggles.VIEW_TYPE_TABLE);
    }

    handleSetSingleCardsView() {
        this.setState({ viewType: TableViewToggles.VIEW_TYPE_SINGLE_CARD });
        this.props.onViewTypeChange(TableViewToggles.VIEW_TYPE_SINGLE_CARD);
    }

    handleSetMultiCardsView() {
        this.setState({ viewType: TableViewToggles.VIEW_TYPE_MULTI_CARDS });
        this.props.onViewTypeChange(TableViewToggles.VIEW_TYPE_MULTI_CARDS);
    }

    render() {
        const { viewType } = this.state;
        const { className, disabledViewTypes, disabled } = this.props;

        const wrapperClassNames = classNames(
            'TableViewToggles',
            'btn-group',
            'display-flex',
            'flex-row',
            className && className
        );

        const buttonClassNames = classNames(
            'btn btn-default btn-icon-only',
            disabled && 'disabled pointer-events-none'
        );

        const tableViewActiveClasses = classNames(
            buttonClassNames,
            (viewType === TableViewToggles.VIEW_TYPE_TABLE) && 'active'
        );

        const cardViewActiveClasses = classNames(
            buttonClassNames,
            (viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD) && 'active'
        );

        const multiCardsViewActiveClasses = classNames(
            buttonClassNames,
            (viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS) && 'active'
        );

        return (
            <div className={wrapperClassNames}>
                {!disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_TABLE) &&
                    <button
                        className={tableViewActiveClasses}
                        type='button'
                        onClick={this.handleSetTableView}>
                        <span className='rioglyph rioglyph-table-view'></span>
                    </button>
                }
                {!disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_SINGLE_CARD) &&
                    <button
                        className={cardViewActiveClasses}
                        type='button'
                        onClick={this.handleSetSingleCardsView}>
                        <span className='rioglyph rioglyph-th-list'></span>
                    </button>
                }
                {!disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_MULTI_CARDS) &&
                    <button
                        className={multiCardsViewActiveClasses}
                        type='button'
                        onClick={this.handleSetMultiCardsView}>
                        <span className='rioglyph rioglyph-split-view'></span>
                    </button>
                }
            </div>
        );
    }
}

TableViewToggles.VIEW_TYPE_TABLE = 'TABLE';
TableViewToggles.VIEW_TYPE_SINGLE_CARD = 'SINGLE_CARD';
TableViewToggles.VIEW_TYPE_MULTI_CARDS = 'MULTI_CARDS';

TableViewToggles.defaultProps = {
    initialViewType: TableViewToggles.VIEW_TYPE_TABLE,
    onViewTypeChange: () => {},
    disabledViewTypes: [],
    disabled: false,
};

TableViewToggles.propTypes = {
    viewType: PropTypes.oneOf([
        TableViewToggles.VIEW_TYPE_TABLE,
        TableViewToggles.VIEW_TYPE_SINGLE_CARD,
        TableViewToggles.VIEW_TYPE_MULTI_CARDS,
    ]),
    initialViewType: PropTypes.oneOf([
        TableViewToggles.VIEW_TYPE_TABLE,
        TableViewToggles.VIEW_TYPE_SINGLE_CARD,
        TableViewToggles.VIEW_TYPE_MULTI_CARDS,
    ]),
    disabledViewTypes: PropTypes.arrayOf(PropTypes.oneOf([
        TableViewToggles.VIEW_TYPE_TABLE,
        TableViewToggles.VIEW_TYPE_SINGLE_CARD,
        TableViewToggles.VIEW_TYPE_MULTI_CARDS,
    ])),
    onViewTypeChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default TableViewToggles;
