import React from 'react';

const StatsWidgets = props => {
    const { children, ...remainingProps } = props;

    return (
        <div {...remainingProps} className={'StatsWidget-wrapper'}>
            {children}
        </div>
    );
};

export default StatsWidgets;
