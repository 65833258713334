import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableToolbar = ({ className, children }) => {
    const wrapperClassNames = classNames(
        'table-toolbar',
        className && className
    );
    return (
        <div className={wrapperClassNames}>
            {children}
        </div>
    );
};

TableToolbar.defaultProps = {};

TableToolbar.propTypes = {
    className: PropTypes.string,
};

export default TableToolbar;
