import React from 'react';
import PropTypes from 'prop-types';
import getMenuItems from '../menuItems/getMenuItems';
import menuItemsPropTypes from './menuItemsPropTypes';
import classNames from 'classnames';

const DropdownSubmenu = (props) => {
    const { title, items, disabled, className } = props;

    const classes = classNames(
        'dropdown-submenu',
        disabled && 'disabled',
        className && className
    );

    return (
        <li className={classes}>
            <a className={'submenu-title'}>
                <span className={'submenu-title-text'}>{title}</span>
                <span className={'rioglyph rioglyph-menu-right'}></span>
            </a>
            <ul className={'dropdown-menu'}>
                {getMenuItems(items)}
            </ul>
        </li>
    );
};

DropdownSubmenu.defaultProps = {
    title: '',
    items: [],
    disabled: false,
};

DropdownSubmenu.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
    ]),
    items: menuItemsPropTypes,
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default DropdownSubmenu;
