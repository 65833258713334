import PropTypes from 'prop-types';

const TreeCategory = props => props.children || null;

TreeCategory.displayName = 'TreeCategory';

TreeCategory.propTypes = {
    id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hasSelection: PropTypes.bool,
};

export default TreeCategory;
