import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import classname from 'classnames';
import isEqual from 'lodash/fp/isEqual';

import { BaseDropdownMenu } from './BaseDropdownMenu';

const DEFAULT_FOCUSED_ITEM_INDEX = -1;

export class Select extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedItem: null,
            isFilterActive: false,
            filterValue: null,
            filteredOptions: props.options,
            itemDOMValues: [],
            focusedItemIndex: DEFAULT_FOCUSED_ITEM_INDEX,
            keyboardUsed: false,
        };

        this.onToggle = this.onToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateSelectedItem = this.updateSelectedItem.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
        this.updateDOMValues = this.updateDOMValues.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onOpenMenu = this.onOpenMenu.bind(this);
    }

    componentWillMount() {
        this.updateSelectedItem(this.props.options, this.props.value);
    }

    componentWillReceiveProps(nextProps) {
        this.updateSelectedItem(nextProps.options, nextProps.value);

        if (!isEqual(nextProps.options, this.props.options)) {
            this.setState({
                filteredOptions: nextProps.options,
                requestItemDOMValues: true,
            });
        }
    }

    updateSelectedItem(options, value) {
        if (value) {
            this.setState(() => ({
                selectedItem: options.find(item => item.id === value[0]),
            }));
        } else if (options) {
            this.setState(() => ({
                selectedItem: options.find(item => item.selected),
            }));
        }
    }

    updateDOMValues(itemDOMValues) {
        this.setState(() => ({
            itemDOMValues,
            requestItemDOMValues: false,
        }));
    }

    render() {
        const { className, hasError } = this.props;
        const dropup = this.isAutoDropActive() ? this.state.dropup : this.props.dropup;

        const classes = classname(
            'select',
            dropup && 'dropup',
            'dropdown',
            hasError && 'has-error',
            this.state.isOpen && 'open',
            className && className
        );

        return (
            <div className={classes} ref={node => (this.refSelect = node)}>
                {this.renderToggle()}
                {this.renderDropdownMenu()}
            </div>
        );
    }

    renderUnselectedItemIcons(options, selectedItem) {
        const optionSpans = options
            .filter(option => !option.header)
            .map(option => (
                <span
                    key={option.id}
                    className={`margin-right-5 ${selectedItem && selectedItem.id === option.id ? '' : 'inactiveIcon'}`}
                >
                    {option.icon}
                </span>
            ));

        return <span>{optionSpans}</span>;
    }

    getSeletedItemLabel(selectedItem, placeholder) {
        if (this.props.showUnselectedItemIcons) {
            return this.renderUnselectedItemIcons(this.props.options, selectedItem);
        }

        if (!selectedItem) {
            // render a placeholder or if there is none render a non-brealing space "&nbsp;"
            return placeholder ? <span className={'placeholder'}>{placeholder}</span> : '\u00a0';
        }

        return (
            <span>
                {selectedItem.icon && <span className={'margin-right-5'}>{selectedItem.icon}</span>}
                {!this.props.showSelectedItemIcon && selectedItem.label}
            </span>
        );
    }

    renderToggle() {
        const { bsSize, tabIndex, useFilter, placeholder, disabled, name, id = name, toggleButtonLabel } = this.props;
        const { isOpen, selectedItem } = this.state;

        const classnames = classname(
            'dropdown-toggle',
            'form-control',
            'text-left',
            bsSize === 'large' && 'input-lg', // TODO: deprecte since it's not consistent
            bsSize === 'small' && 'input-sm', // TODO: deprecte since it's not consistent
            bsSize === 'sm' && 'input-sm',
            bsSize === 'lg' && 'input-lg',
            disabled && 'disabled'
        );

        const selectedLabel = (
            <span className={'width-100pct'}>{this.getSeletedItemLabel(selectedItem, placeholder)}</span>
        );

        return (
            <button
                type={'button'}
                id={id}
                name={name}
                className={classnames}
                data-toggle={'dropdown'}
                tabIndex={tabIndex}
                aria-haspopup={'true'}
                aria-expanded={isOpen}
                onClick={this.onToggle}
                ref={node => (this.refToggle = node)}
            >
                {useFilter && isOpen && this.renderFilterInput()}
                {toggleButtonLabel ? toggleButtonLabel : selectedLabel}
                <span className={'caret'} />
            </button>
        );
    }

    renderDropdownMenu() {
        //console.log('keyboardUsed=' + this.state.keyboardUsed)

        const { pullRight, autoDropDirection, noItemMessage } = this.props;

        // When an option was already selected, highlight this option by setting the focusedItemIndex accordingly.
        // In case there was nothing preselected, set the focusedItemIndex to the first item if keayboard was used.
        let focusedItemIndex = this.state.focusedItemIndex;
        if (this.state.selectedItem) {
            focusedItemIndex = this.props.options.findIndex(option => {
                return option.id === this.state.selectedItem.id;
            });
        } else if (this.state.keyboardUsed) {
            focusedItemIndex = 0;
        }

        //console.log('focusedItemIndex=' + focusedItemIndex);

        return (
            <BaseDropdownMenu
                isOpen={this.state.isOpen}
                options={this.state.filteredOptions}
                focusedItemIndex={focusedItemIndex}
                keyboardUsed={this.state.keyboardUsed}
                updateDOMValues={this.updateDOMValues}
                onOpen={this.onOpenMenu}
                onSelect={this.onOptionChange}
                onClose={this.closeMenu}
                noItemMessage={noItemMessage}
                autoDropDirection={autoDropDirection}
                pullRight={pullRight}
            />
        );
    }

    renderFilterInput() {
        const inputClasses = classname(
            'select-filter-input',
            (this.state.isFilterActive || this.state.filterValue) && 'select-filter-input-active'
        );
        return (
            <input
                type={'text'}
                className={inputClasses}
                autoFocus
                onChange={this.handleFilterChange}
                defaultValue={this.state.filterValue}
            />
        );
    }

    filterOptions(itemDOMValues, filterValue, options) {
        const filteredDOMValues = itemDOMValues.filter(item =>
            item.text.toLowerCase().includes(filterValue.toLowerCase())
        );

        // Filter the options accodring to the filtered DOM values and map the IDs since the filter cannot be done
        // on the options itself as they might contain arbitrary components
        return options.filter(option => {
            return filteredDOMValues.find(value => value.id === option.id);
        });
    }

    handleFilterChange(event) {
        event.preventDefault();

        const filterValue = event.currentTarget.value;
        const filteredOptions = this.filterOptions(this.state.itemDOMValues, filterValue, this.props.options);

        // highlight the first item of the search result if at least one item was found
        const newFocusedItemIndex = filteredOptions.length > 0 ? 0 : DEFAULT_FOCUSED_ITEM_INDEX;

        this.setState(() => ({
            isFilterActive: true,
            filterValue,
            filteredOptions,
            keyboardUsed: true,
            focusedItemIndex: newFocusedItemIndex,
        }));
    }

    onOptionChange(event, selectedItem) {
        this.setState(() => ({
            selectedItem,
            isFilterActive: false,
            filterValue: '',
            filteredOptions: this.props.options,
        }));

        // Needed? - was once added by a Service Team (guess it was Chat)
        if (event.target) {
            event.target.value = this.state.selectedItem;
        }

        this.props.onChange(selectedItem);

        this.closeMenu();
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    onToggle(event) {
        // Dont toggle when component is disabled or
        // when filter is active, means entering some filter value
        // in order to avoid closing menu on space but allow to use it for filtering
        if (this.props.disabled || this.state.isFilterActive) {
            return;
        }

        // using the enter key on the toggle button will trigger a synthetic click event as all buttons are of
        // type submit by default in HTML. In order to differentiate between real click and a synthetic event
        // caused by they keyboard, use the event details. A synthetic event is always 0.
        const keyboardUsed = event.detail === 0;

        this.setState({
            isOpen: !this.state.isOpen,
            keyboardUsed,
        });
    }

    onOpenMenu(dropup) {
        const node = ReactDOM.findDOMNode(this);
        if (dropup) {
            node.classList.add('dropup');
        } else {
            node.classList.remove('dropup');
        }
    }

    closeMenu() {
        if (this.state.isOpen) {
            this.setState(() => ({
                isOpen: false,
                isFilterActive: false,
                keyboardUsed: false,
                focusedItemIndex: DEFAULT_FOCUSED_ITEM_INDEX,
            }));
            this.refToggle.focus();
        }
    }

    handleClickOutside() {
        this.closeMenu();
    }
}

Select.defaultProps = {
    onChange: () => {},
    options: [],
    disabled: false,
    autoDropDirection: true,
    pullRight: false,
    dropup: false,
    hasError: false,
    useFilter: false,
    tabIndex: 0,
    showSelectedItemIcon: false,
    showUnselectedItemIcons: false,
};

Select.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // Identify an option
            id: PropTypes.string.isRequired,
            // A label to show in body
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            // An icon to show in body
            icon: PropTypes.node,
            selected: PropTypes.bool,
            disabled: PropTypes.bool,
            header: PropTypes.bool,
        })
    ),
    onChange: PropTypes.func,
    // Label that will be used instead of the selected item label
    toggleButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // Text to display when no menu item is selected
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    // FIXME: "value" should be removed - use options.selected instead
    value: PropTypes.array,
    bsSize: PropTypes.oneOf(['sm', 'lg', 'small', 'large']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    hasError: PropTypes.bool,
    useFilter: PropTypes.bool,
    // Text to display when no menu item is selected
    noItemMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // shows icons as selected values
    showSelectedItemIcon: PropTypes.bool,
    // shows icons always with active or inactive state
    showUnselectedItemIcons: PropTypes.bool,
};

export default onClickOutside(Select);
