import React from 'react';
import PropTypes from 'prop-types';

function formatDegrees(degrees, positivePrefix, negativePrefix) {
    const prefix = degrees >= 0 ? positivePrefix : negativePrefix;
    const absDegrees = Math.abs(degrees);
    const flooredDegrees = Math.floor(absDegrees);
    const minutes = (absDegrees - flooredDegrees) * 60;
    return `${prefix}${flooredDegrees}\u00b0 ${minutes.toFixed(3)}\u2032`;
}

/*
 * Presentational component for a formatted coordinate.
 */
const Position = (props) => {
    const { vehicleState } = props;
    const lat = vehicleState.latitude;
    const lon = vehicleState.longitude;
    const address = vehicleState.address;

    let formattedAddress;
    if (!address) {
        formattedAddress = `${formatDegrees(lat, 'N', 'S')} ${formatDegrees(lon, 'E', 'W')}`;
    } else {
        formattedAddress = address;
    }

    return <span className='vehiclePositionInfo'>{formattedAddress}</span>;
};

Position.propTypes = {
    vehicleState: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
        address: PropTypes.string,
    }),
};

export default Position;
