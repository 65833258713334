import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortDirection } from '../../utils/SortUtil';
import Select from '../selects/Select';
import ToggleButton from '../button/ToggleButton';

class TableCardsSorting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sortName: this.props.sortName,
            sortOrder: this.props.sortOrder,
        };

        this.setSortName = this.setSortName.bind(this);
        this.setSortDirectionAsc = this.setSortDirectionAsc.bind(this);
        this.setSortDirectionDesc = this.setSortDirectionDesc.bind(this);
    }

    setSortDirectionAsc() {
        this.setSortDirection(SortDirection.ASCENDING);
    }

    setSortDirectionDesc() {
        this.setSortDirection(SortDirection.DESCENDING);
    }

    setSortDirection(direction) {
        this.setState({
            sortOrder: direction,
        });

        this.props.onSortChange(this.state.sortName, direction);
    }

    setSortName(item) {
        this.setState({
            sortName: item.id,
        });

        this.props.onSortChange(item.id, this.state.sortOrder);
    }

    render() {
        const { className, selectOptions } = this.props;
        const { sortOrder } = this.state;

        const wrapperClassNames = classNames(
            'table-toolbar-cards-sorting',
            'btn-toolbar',
            className && className
        );

        return (
            <div className={wrapperClassNames}>
                <div className='btn-group margin-left-0'>
                    <ToggleButton
                        className={'btn-icon-only'}
                        active={sortOrder === SortDirection.ASCENDING}
                        onClick={this.setSortDirectionAsc}>
                        <span className='rioglyph rioglyph-sort-by-attributes-alt'></span>
                    </ToggleButton>
                    <ToggleButton
                        className={'btn-icon-only'}
                        active={sortOrder === SortDirection.DESCENDING}
                        onClick={this.setSortDirectionDesc}>
                        <span className='rioglyph rioglyph-sort-by-attributes'></span>
                    </ToggleButton>
                </div>
                {selectOptions.length &&
                    <div className='btn-group'>
                        <Select options={selectOptions} onChange={this.setSortName}/>
                    </div>
                }
            </div>
        );
    }
}

TableCardsSorting.defaultProps = {
    onSortChange: () => {},
    sortOrder: SortDirection.ASCENDING,
    selectOptions: [],
};

TableCardsSorting.propTypes = {
    sortName: PropTypes.string.isRequired,
    sortOrder: PropTypes.oneOf([
        SortDirection.ASCENDING,
        SortDirection.DESCENDING,
    ]).isRequired,
    onSortChange: PropTypes.func,
    selectOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
    })).isRequired,
    className: PropTypes.string,
};

export default TableCardsSorting;
