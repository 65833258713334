import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classname from 'classnames';
import isEqual from 'lodash/fp/isEqual';

import ownerDocument from '../../utils/ownerDocument';
import addEventListener from '../../utils/addEventListener';
import getDropDirection from '../../utils/getDropDirection';
import scrollItemIntoView, { UP, DOWN } from '../../utils/scrollItemIntoView';
import DropdownHeader from './DropdownHeader';

// const UP = 'up';
// const DOWN = 'down';
const DATA_ATTRIBUTE_ID = 'data-item-id';
const DATA_ATTRIBUTE_INDEX = 'data-item-index';
const DEFAULT_FOCUSED_ITEM_INDEX = -1;
const HIGHLIGHT_CLASS = 'focus';

export class BaseDropdownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropup: false,
            pullRight: props.pullRight,
            focusedItemIndex: this.props.focusedItemIndex,
            keyboardUsed: this.props.keyboardUsed,
            itemDOMValues: null,
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }

    componentDidMount() {
        // all available items need to be rendered in order to know their DOM value
        // which will be used for filtering in the parent component
        const itemDOMValues = this.setItemDOMValues();
        this.setState(() => ({
            itemDOMValues,
        }));
        this.props.updateDOMValues(itemDOMValues);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            if (!this.props.isOpen && nextProps.isOpen) {
                this.openMenu(nextProps);
            } else if (this.props.isOpen && !nextProps.isOpen) {
                this.closeMenu();
            }

            if (!isEqual(nextProps.options, this.props.options)) {
                // Only update the DOM values if it is explicitly requested
                // otherwise it will reduce the dom values to the filtered options
                let updatedItemDOMValues;
                if (nextProps.requestItemDOMValues) {
                    updatedItemDOMValues = this.setItemDOMValues();
                }

                this.setState(() => ({
                    options: nextProps.options,
                    itemDOMValues: updatedItemDOMValues ? updatedItemDOMValues : this.state.itemDOMValues,
                }));

                if (nextProps.requestItemDOMValues) {
                    this.props.updateDOMValues(updatedItemDOMValues);
                }
            }
        }
    }

    handleKeydown(event) {
        switch (event.keyCode) {
            case 27:
                // close on esc key or on tab
                this.closeMenu();
                break;
            case 9:
                // close on tab
                this.closeMenu();
                break;
            case 13:
                // select on enter
                this.selectOptionOnEnter(event);
                break;
            case 38:
                // prevent scrolling the page when dropdown menu is open
                event.preventDefault();

                // select above on arrow up key
                this.focusOption(UP);
                scrollItemIntoView(UP, this.getDropdownDOMNode(), this.getFocusedOptionNode());
                break;
            case 40:
                // prevent scrolling the page when dropdown menu is open
                event.preventDefault();

                // select below on arrow down key
                this.focusOption(DOWN);
                scrollItemIntoView(DOWN, this.getDropdownDOMNode(), this.getFocusedOptionNode());
                break;
            default:
                break;
        }
    }

    focusOption(direction) {
        let nextFocusedItem = 0;
        const focusedItemIndex = this.state.focusedItemIndex;

        switch (direction) {
            case UP:
                nextFocusedItem = focusedItemIndex === 0 ? focusedItemIndex : focusedItemIndex - 1;
                break;
            case DOWN:
                nextFocusedItem =
                    focusedItemIndex === this.props.options.length - 1 ? focusedItemIndex : focusedItemIndex + 1;
                break;
            default:
                break;
        }

        this.setState(() => ({
            focusedItemIndex: nextFocusedItem,
            keyboardUsed: true,
        }));
    }

    getDropdownDOMNode() {
        return ReactDOM.findDOMNode(this.refDropdownMenu);
    }

    getOptionNodes() {
        const node = ReactDOM.findDOMNode(this.refDropdownMenu);
        if (!node) {
            return [];
        }
        return node.getElementsByTagName('a') || [];
    }

    setItemDOMValues() {
        if (this.refDropdownMenu) {
            const optionNodes = this.getOptionNodes();
            const itemDOMValues = [...optionNodes].map(item => {
                return {
                    id: item.getAttribute(DATA_ATTRIBUTE_ID),
                    text: item.textContent,
                };
            });

            return itemDOMValues;
        }
    }

    getFocusedOptionNode() {
        const optionNodes = this.getOptionNodes();
        return [...optionNodes].find(item => item.className.includes(HIGHLIGHT_CLASS));
    }

    selectOptionOnEnter(event) {
        event.preventDefault();

        // When no filter result was found, avoid selecting anything
        if (!this.props.options.length) {
            return;
        }

        const match = this.getFocusedOptionNode();

        if (match) {
            const selectedItem = this.props.options.find(option => option.id === match.getAttribute(DATA_ATTRIBUTE_ID));

            this.props.onSelect(event, selectedItem);
        }
    }

    render() {
        const { pullRight, noItemMessage } = this.props;

        const isPullRight = this.isAutoDropActive() ? this.state.pullRight : pullRight;

        const dropdownMenuClasses = classname('dropdown-menu', isPullRight && 'pull-right');

        // Dont show dropdown, when no match are found when filtering unless there is a not found message
        if (!this.props.options.length) {
            // In case a message is provided when nothing matched, show it
            if (noItemMessage) {
                return (
                    <ul className={dropdownMenuClasses}>
                        <li className={'no-item-message disabled'} disabled>
                            <a role={'button'}>
                                <i>{noItemMessage}</i>
                            </a>
                        </li>
                    </ul>
                );
            }
            return null;
        }

        return (
            <ul className={dropdownMenuClasses} ref={node => (this.refDropdownMenu = node)}>
                {this.props.options.map((option, index) => this.renderMenuOption(option, index))}
            </ul>
        );
    }

    renderMenuOption(option, index) {
        if (option.header) {
            return <DropdownHeader key={option.id} id={option.id} icon={option.icon} label={option.label} type={'dropdown'}/>;
        }

        // Show focused style only when keyboard is in use
        const anchorClassNames =
            this.state.keyboardUsed && this.state.focusedItemIndex === index ? HIGHLIGHT_CLASS : '';

        const classNames = classname(
            option.disabled && 'disabled',
            this.props.useActiveClass && option.selected && 'active'
        );

        return (
            <li key={option.id} className={classNames}>
                <a
                    role={'button'}
                    className={anchorClassNames}
                    data-item-id={option.id}
                    data-item-index={index}
                    onClick={!option.disabled ? this.handleOptionChange : undefined}
                >
                    <span>
                        {option.icon && <span className={'margin-right-5'}>{option.icon}</span>}
                        {option.label}
                    </span>
                    <input type={'hidden'} value={option.id} />
                </a>
            </li>
        );
    }

    getSelectedFocusedItemIndex() {
        return event.currentTarget.getAttribute(DATA_ATTRIBUTE_INDEX) || -1;
    }

    handleOptionChange(event) {
        event.preventDefault();

        const optionId = event.currentTarget.getElementsByTagName('input')[0].value;
        const selectedItem = this.props.options.find(option => option.id === optionId);

        this.props.onSelect(event, selectedItem);
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    openMenu(nextProps) {
        const { options, onOpen } = this.props;

        const doc = ownerDocument(this);
        this.onDocumentKeydownListener = addEventListener(doc, 'keydown', this.handleKeydown);

        const dropDirection =
            options.length && this.isAutoDropActive()
                ? getDropDirection(this.refDropdownMenu.parentNode, this.refDropdownMenu)
                : {};

        this.setState(() => ({
            isOpen: nextProps.isOpen,
            keyboardUsed: nextProps.keyboardUsed,
            ...dropDirection,
        }));

        // Tell the parent whether it's dropup or not as it might need to set the respective class
        // to the wrapping element
        onOpen(dropDirection.dropup);
    }

    closeMenu() {
        if (this.onDocumentKeydownListener) {
            this.onDocumentKeydownListener.remove();
        }

        this.props.onClose();
    }
}

BaseDropdownMenu.defaultProps = {
    isOpen: false,
    updateDOMValues: () => {},
    onOpen: () => {},
    onSelect: () => {},
    onClose: () => {},
    options: [],
    autoDropDirection: true,
    pullRight: false,
    useActiveClass: false,
    keyboardUsed: false,
    focusedItemIndex: DEFAULT_FOCUSED_ITEM_INDEX,
};

BaseDropdownMenu.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired /* Identify an option */,
            label: PropTypes.any.isRequired /* A label to show in body */,
            icon: PropTypes.object /* An icon to show in body */,
            selected: PropTypes.bool,
            disabled: PropTypes.bool,
            header: PropTypes.bool,
        })
    ),
    isOpen: PropTypes.bool,
    updateDOMValues: PropTypes.func,
    onOpen: PropTypes.func,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    noItemMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    focusedItemIndex: PropTypes.number.isRequired,
};
