import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '../button/Button';
import classNames from 'classnames';

export default class FilePicker extends Component {

    render() {
        let dropzoneProps = {
            accept: this.props.accept,
            multiple: this.props.multiple,
            ref: (thisDropzone) => {
                this.thisDropzone = thisDropzone;
            },
            onDrop: this.onPick.bind(this),
            maxSize: this.props.maxSize,
        };

        //let content;
        // TODO Reactivate once the dropzone is available
        // if (this.props.displayMode === 'dropzone') {
        //     //Dropzone
        //     // TODO className and activeClassName/rejectClassName are deprecated.
        //     // TODO They will have to be replaced respectively with parent div styling and
        //     // TODO child div styling with function.
        //     // TODO This is not possible yet because this Dropzone version still contains a default styling.
        //     dropzoneProps = {
        //         className: classNames('react-dropzone', this.props.className),
        //         activeClassName: 'react-dropzone-active',
        //         rejectClassName: 'react-dropzone-rejected',
        //         ...dropzoneProps,
        //     };
        //     content = <Dropzone {...dropzoneProps} />;
        // } else {
            // Button
            // TODO className will be deprecated in the following major release.
            // TODO It will have to be replaced with parent div styling,
            // TODO This is not possible yet because this Dropzone version still contains a default styling.
        dropzoneProps = {
            className: classNames('invisible'),
            ...dropzoneProps,
        };
        const content =
            <div>
                <Dropzone {...dropzoneProps} />
                <Button onClick={this.onOpenClick.bind(this)} className={this.props.className}>
                    {this.props.label}
                </Button>
            </div>;
        // }

        return (
            <div className='FilePicker'>
                {content}
            </div>
        );
    }

    onPick(acceptedFiles, rejectedFiles) {
        this.props.onPick(acceptedFiles, rejectedFiles);
    }

    onOpenClick() {
        this.thisDropzone.open();
    }
}

FilePicker.defaultProps = {
    displayMode: 'button',
    multiple: true,
    label: 'Select file(s)',
    accept: '',
    onPick: () => {},
    className: '',
};

FilePicker.propTypes = {
    displayMode: PropTypes.string, /* Display mode: 'dropzone' / 'button' */
    multiple: PropTypes.bool,      /* Allow multiple file selections or not */
    label: PropTypes.oneOfType([   /* Text to display on Button */
        PropTypes.string,
        PropTypes.node,
    ]),
    accept: PropTypes.string,      /* List of accepted Mime Types */
    maxSize: PropTypes.number,     /* Maximum File Size */
    onPick: PropTypes.func,        /* Pick callback */
    className: PropTypes.string,   /* Custom className for the displayed component (dropzone/button) */
};
