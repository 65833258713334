import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import classname from 'classnames';

export default class SteppedProgressBar extends Component {

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.state = {
            disableFollowingPages: props.disableFollowingPages ? props.disableFollowingPages :
                this.props.selectedStepNumber,
            isValid: true,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disableFollowingPages !== undefined) {
            if (nextProps.disableFollowingPages < nextProps.selectedStepNumber) {
                // eslint-disable-next-line no-console
                console.warn('Invalid component state detected in SteppedProgressBar: can not disable following ' +
                    `pages ${nextProps.disableFollowingPages} when selectedStep is ${nextProps.selectedStep}!`);

                this.setState({
                    isValid: false,
                });
                return;
            }

            this.setState({
                disableFollowingPages: nextProps.disableFollowingPages,
            });

        } else if (nextProps.selectedStepNumber > this.state.disableFollowingPages) {
            this.setState({
                disableFollowingPages: nextProps.selectedStepNumber,
            });
        }
    }

    handleSelect(selectedKey) {
        this.props.onSelectedChanged(selectedKey);
    }

    getItems(labels) {
        return labels.map((labelObject, index) => {
            return (
                <NavItem key={index} eventKey={index} className='step-tab'
                    disabled={index > this.state.disableFollowingPages}>
                    <div className='item-icon'>
                        {labelObject.icon}
                    </div>
                    <div className={classname('item-label', this.props.labelsClassName)}>
                        {labelObject.label}
                    </div>
                </NavItem>
            );
        });
    }

    render() {
        if (!this.state.isValid) {
            return <b>{`FIXME: INVALID STATE DETECTED!`}</b>;
        }

        const selectedStepNumber = this.props.selectedStepNumber;
        return (
            <Nav className={classname('stepped-progress-bar', this.props.className)} activeKey={selectedStepNumber}
                onSelect={(selectedKey) => this.handleSelect(selectedKey)}>
                {this.getItems(this.props.labels)}
            </Nav>
        );
    }
}

SteppedProgressBar.defaultProps = {
    selectedStepNumber: 1,
    labels: [],
    onSelectedChanged: () => {},
};

SteppedProgressBar.propTypes = {
    disableFollowingPages: PropTypes.number,
    onSelectedChanged: PropTypes.func.isRequired,
    labels: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]).isRequired,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
    })).isRequired,
    selectedStepNumber: PropTypes.number.isRequired,
    className: PropTypes.string,
    labelsClassName: PropTypes.string,
};
