import React from 'react';
import PropTypes from 'prop-types';

const TypeCounter = ({ icon, value }) => {
    return value ? (
        <div className={'user-select-none margin-right-15'}>
            <span className={`rioglyph rioglyph-${icon} text-size-16`} />
            <span className={'TreeLabelCount margin-left-3 label label-muted label-filled label-condensed'}>
                {value}
            </span>
        </div>
    ) : null;
};

TypeCounter.propTypes = {
    icon: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
};

export default TypeCounter;
