import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import getMenuItems from '../menuItems/getMenuItems';
import menuItemsPropTypes from './menuItemsPropTypes';
import getDropDirection from '../../utils/getDropDirection';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

class ButtonDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open,
            dropup: props.dropup,
            pullRight: props.pullRight,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleSplitLabelButtonClick = this.handleSplitLabelButtonClick.bind(this);
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    toggleOpen() {
        if (this.state.open) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    openMenu() {
        const toggleNode = ReactDOM.findDOMNode(this.refDropdownToggle);
        const menuNode = ReactDOM.findDOMNode(this.refDropdownMenu);
        const dropDirection = this.isAutoDropActive() ? getDropDirection(toggleNode, menuNode) : {};

        this.setState({
            open: true,
            ...dropDirection,
        });

        this.props.enableOnClickOutside();
    }

    closeMenu() {
        this.setState({
            open: false,
        });

        this.props.disableOnClickOutside();
    }

    handleClickOutside() {
        this.closeMenu();
    }

    handleSplitLabelButtonClick() {
        this.closeMenu();

        if (this.props.onLabelButtonClick) {
            this.props.onLabelButtonClick();
        }
    }

    render() {
        const {
            id,
            bsSize,
            bsStyle,
            disabled,
            items,
            iconOnly,
            title,
            noCaret,
            splitButton,
            className,
            toggleClassName,
            customDropdown,
        } = this.props;

        const { open } = this.state;

        const dropup = this.isAutoDropActive() ? this.state.dropup : this.props.dropup;
        const pullRight = this.isAutoDropActive() ? this.state.pullRight : this.props.pullRight;

        const classes = classNames('dropdown', 'btn-group', open && 'open', dropup && 'dropup', className && className);

        const labelButtonClasses = classNames(
            !splitButton && 'dropdown-toggle',
            'btn',
            `btn-${bsStyle}`,
            iconOnly && 'btn-icon-only',
            toggleClassName && !splitButton && toggleClassName,
            bsSize === 'xs' && 'btn-xs',
            bsSize === 'sm' && 'btn-sm',
            bsSize === 'lg' && 'btn-lg',
            disabled && 'disabled'
        );

        const dropdownMenuClasses = classNames('dropdown-menu', pullRight && 'pull-right');

        const splitCaretButtonClasses = classNames(
            'dropdown-toggle',
            'btn',
            `btn-${bsStyle}`,
            disabled && 'disabled',
            toggleClassName && toggleClassName
        );

        const caret = <span className={'caret'}></span>;
        const splitCaretButton = (
            <button
                id={id}
                role={'button'}
                type={'button'}
                className={splitCaretButtonClasses}
                onClick={this.toggleOpen}
            >
                {caret}
            </button>
        );

        const labelButtonId = splitButton ? `button-${id}` : id;
        const handleDropdownButtonClick = splitButton ? this.handleSplitLabelButtonClick : this.toggleOpen;

        return (
            <div className={classes}>
                <button
                    id={labelButtonId}
                    role={'button'}
                    type={'button'}
                    className={labelButtonClasses}
                    ref={node => (this.refDropdownToggle = node)}
                    onClick={handleDropdownButtonClick}
                >
                    {title}
                    {!noCaret && !splitButton && !iconOnly && caret}
                </button>
                {splitButton && splitCaretButton}
                <ul role={'menu'} className={dropdownMenuClasses} ref={node => (this.refDropdownMenu = node)}>
                    {customDropdown ? customDropdown : getMenuItems(items, this.toggleOpen)}
                </ul>
            </div>
        );
    }
}

ButtonDropdown.defaultProps = {
    id: Math.random()
        .toString(36)
        .substr(2, 16),
    items: [],
    iconOnly: false,
    noCaret: false,
    pullRight: false,
    splitButton: false,
    dropup: false,
    autoDropDirection: true,
    bsStyle: 'default',
    disabled: false,
};

ButtonDropdown.propTypes = {
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    bsSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success', 'link', 'muted']),
    iconOnly: PropTypes.bool,
    noCaret: PropTypes.bool,
    splitButton: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    items: menuItemsPropTypes.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    toggleClassName: PropTypes.string,
    onLabelButtonClick: PropTypes.func,
    customDropdown: PropTypes.node,
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
};

const EnhancedComponent = onClickOutside(ButtonDropdown);

export default function Container(props) {
    return <EnhancedComponent {...props} disableOnClickOutside={true} />;
}
