import React from 'react';
import MenuItem from './MenuItem';

const getMenuItems = (items, closeMenu, onMouseEnter) => {
    return items.map((item, index) => (
        <MenuItem key={index} {...item} index={index} closeMenu={closeMenu} onMouseEnter={onMouseEnter} />
    ));
};

export default getMenuItems;
