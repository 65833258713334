import React from 'react';
import classNames from 'classnames';

const StatsWidgetSpacer = props => {
    const { className, ...remainingProps } = props;
    const classes = classNames('StatsWidget-body-column-spacer', className);
    return <div {...remainingProps} className={classes} />;
};

export default StatsWidgetSpacer;
