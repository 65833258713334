import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ApplicationLayoutHeader from './ApplicationLayoutHeader';
import ApplicationLayoutSidebar from './ApplicationLayoutSidebar';
import ApplicationLayoutBody from './ApplicationLayoutBody';

export const ApplicationLayout = (props) => {
    const { className, children } = props;

    const classes = classNames('ApplicationLayout', className);

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

ApplicationLayout.Header = ApplicationLayoutHeader;
ApplicationLayout.Sidebar = ApplicationLayoutSidebar;
ApplicationLayout.Body = ApplicationLayoutBody;

ApplicationLayout.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayout;
