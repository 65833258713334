export const PORTAL_ROOT_ID = 'uikit-root';

export const getOrCreatePortalRoot = () => {
    const dropdownRoot = document.getElementById(PORTAL_ROOT_ID);

    const newRoot = document.createElement('div');
    newRoot.setAttribute('id', PORTAL_ROOT_ID);

    return dropdownRoot ? dropdownRoot : document.body.appendChild(newRoot);
};
