import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';

import Tooltip from '../tooltip/Tooltip';

const ACTIVE_CLASS_NAME = 'active';
const SELECTED_CLASS_NAME = 'selected';

const LABEL_TOOLTIP_DELAY = 1500;
const LABEL_TOOLTIP_PLACEMENT = 'right';

const renderCategories = (categories, currentCategoryId, onSelectCategory) =>
    React.Children.map(categories, child => {
        const id = child.props.id;
        const icon = child.props.icon || '';
        const label = child.props.label;

        const statusClassName = classNames(
            currentCategoryId === id ? ACTIVE_CLASS_NAME : '',
            child.props.hasSelection && SELECTED_CLASS_NAME
        );

        const handleSelectCategory = event => {
            event.stopPropagation();
            onSelectCategory(id);
        };

        if (label) {
            const tooltip = <span>{label}</span>;
            return (
                <OverlayTrigger
                    placement={LABEL_TOOLTIP_PLACEMENT}
                    delayShow={LABEL_TOOLTIP_DELAY}
                    overlay={
                        <Tooltip id={'tooltip'} className={'right-top'} width={'auto'}>
                            {tooltip}
                        </Tooltip>
                    }
                >
                    <li key={id} onClick={handleSelectCategory} className={statusClassName}>
                        <div className={'selection-bubble'}>
                            <span className={`rioglyph ${icon}`}></span>
                        </div>
                    </li>
                </OverlayTrigger>
            );
        }

        return (
            <li key={id} onClick={handleSelectCategory} className={statusClassName}>
                <span className={`rioglyph ${icon}`}></span>
            </li>
        );
    });

const TreeSidebar = props => {
    const { className, categories, currentCategoryId, onSelectCategory, onClick } = props;

    const classes = classNames('TreeSidebar', className);

    return (
        <div className={classes}>
            <ul className={'TreeSidebarNavigation'} onClick={onClick}>
                {renderCategories(categories, currentCategoryId, onSelectCategory)}
            </ul>
            <div className={'TreeSidebarToggle'} onClick={onClick}>
                <span className={'rioglyph rioglyph-chevron-left'}></span>
            </div>
        </div>
    );
};

TreeSidebar.displayName = 'TreeSidebar';

TreeSidebar.defaultProps = {
    onClick: () => {},
};

TreeSidebar.propTypes = {
    currentCategoryId: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.node),
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default TreeSidebar;
