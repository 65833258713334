import React from 'react';
import PropTypes from 'prop-types';
import Fade from '../fade/Fade';

const DataTab = ({ disableTransition, children, active, mountOnEnter, unmountOnExit }) => {
    const content = disableTransition ? <div>{active && children}</div> :
        <Fade
            in={active}
            mountOnEnter={mountOnEnter}
            unmountOnExit={unmountOnExit}>
                <div>
                    {active && children}
                </div>
        </Fade>;

    return content;
};

DataTab.defaultProps = {
    active: false,
    disableTransition: false,
};

// Props are injected from the wrapping DataTabs component
DataTab.propTypes = {
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    className: PropTypes.string,
};

export default DataTab;
