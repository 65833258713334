import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Spinner = (props) => {
    const { isInverse, isDoubleSized, isFullSized } = props;

    const spinnerClasses = classNames(
        'spinner',
        isDoubleSized && 'double',
        isInverse && 'inverse'
    );

    if (isFullSized) {
        return (
            <div className='height-100pct display-flex justify-content-center align-items-center'>
                <div className={'spinner double'} />
            </div>
        );
    }

    return (
        <div className={spinnerClasses} />
    );
};

Spinner.defaultProps = {
    isInverse: false,
    isDoubleSized: false,
    isFullSized: false,
};

Spinner.propTypes = {
    isInverse: PropTypes.bool,
    isDoubleSized: PropTypes.bool,
    isFullSized: PropTypes.bool,
};

export default Spinner;
