// https://github.com/react-bootstrap/react-bootstrap/blob/v0.32.0/src/CarouselCaption.js

import classNames from 'classnames';
import React from 'react';

class CarouselCaption extends React.Component {
    render() {
        const { componentClass: Component, className, ...remainingProps } = this.props;
        return <Component {...remainingProps} className={classNames('carousel-caption', className)} />;
    }
}

CarouselCaption.defaultProps = {
    componentClass: 'div',
};

CarouselCaption.propTypes = {
    // componentClass: elementType,
};

export default CarouselCaption;
