import PropTypes from 'prop-types';

export const ALIGN_LEFT = 'left';
export const ALIGN_CENTER = 'center';
export const ALIGN_RIGHT = 'right';

export const baseStatePropTypes = {
    icon: PropTypes.string,
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            className: PropTypes.string,
        })
    ),
    image: PropTypes.node,
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            className: PropTypes.string,
            onClick: PropTypes.func,
            href: PropTypes.string,
        })
    ),
    fullWidth: PropTypes.bool,
    condensed: PropTypes.bool,
    alignment: PropTypes.oneOf([ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT]),
    outerClassName: PropTypes.string,
    innerClassName: PropTypes.string,
};
