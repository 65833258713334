import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import activeElement from 'dom-helpers/activeElement';
import contains from 'dom-helpers/query/contains';

import ownerDocument from '../../utils/ownerDocument';
import addEventListener from '../../utils/addEventListener';
import addFocusListener from '../../utils/addFocusListener';
import { CSSTransition } from 'react-transition-group';
import { getOrCreatePortalRoot } from '../../utils/portalRoot';

const MODAL_OPEN_CLASS = 'modal-open';

class Dialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.show,
        };

        this.onShow = this.onShow.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleEntered = this.handleEntered.bind(this);
        this.handleEsc = this.handleEsc.bind(this);
        this.enforceFocus = this.enforceFocus.bind(this);
    }

    componentDidMount() {
        // In case the dialog is initially mounted and rendered with "show=true"
        // from the service
        if (this.props.show) {
            this.onShow();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.show !== nextProps.show) {
            this.setState({
                open: nextProps.show,
            });
        }

        // Remove boddy modal class even when onClose() was not triggered.
        // The only way to check if the doalig was closed is via the "show" prop
        // which is set from the outside.
        if (this.props.show && !nextProps.show) {
            this.toggleBodyClass(ownerDocument(this), false);
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.show && this.props.show) {
            this.onShow();
        } else if (prevProps.show && !this.props.show) {
            this.cleanup();
        }
    }

    componentWillUnmount() {
        this.cleanup();
    }

    toggleBodyClass(doc, add) {
        // We need to set a body class to fix the -webkit-overflow-scrolling on safari and iOS
        if (doc && add) {
            doc.body.classList.add(MODAL_OPEN_CLASS);
        } else if (doc && !add) {
            doc.body.classList.remove(MODAL_OPEN_CLASS);
        }
    }

    onShow() {
        const doc = ownerDocument(this);
        this.toggleBodyClass(doc, true);

        this.onDocumentKeydownListener = addEventListener(doc, 'keydown', this.handleEsc);

        // Needed when tabbing through elements within the dialog to keep focus within the dialog
        this.onFocusinListener = addFocusListener(this.enforceFocus);
    }

    onClose() {
        this.cleanup();

        this.setState({
            open: false,
        });

        this.props.onHide();
    }

    cleanup() {
        this.toggleBodyClass(ownerDocument(this), false);

        if (this.onDocumentKeydownListener) {
            this.onDocumentKeydownListener.remove();
        }
        if (this.onFocusinListener) {
            this.onFocusinListener.remove();
        }
    }

    handleEntered() {
        this.enforceFocus();
    }

    enforceFocus() {
        const dialogElement = this.getDialogElement();
        const currentActiveElement = activeElement(ownerDocument(this));

        if (dialogElement && !contains(dialogElement, currentActiveElement)) {
            if (!dialogElement.hasAttribute('tabIndex')) {
                dialogElement.setAttribute('tabIndex', -1);
            }
            dialogElement.focus();
        }
    }

    getDialogElement() {
        if (this.dialog) {
            return ReactDOM.findDOMNode(this.dialog);
        }
    }

    handleEsc(event) {
        if (!this.props.disableEsc && event.keyCode === 27) {
            // trigger callback for esc key
            if (this.props.onEsc) {
                this.props.onEsc();
            }

            this.onClose();
        }
    }

    renderHeader(title, subtitle, showCloseButton) {
        return (
            <div className={'modal-header'}>
                <div className={'modal-header-text'}>
                    {subtitle && <div className={'modal-header-subtitle'}>{subtitle}</div>}
                    <div className={'modal-header-title'}>{title}</div>
                </div>
                {showCloseButton && (
                    <button type={'button'} className={'modal-header-close close'} onClick={this.onClose}>
                        <span aria-hidden={'true'}>{'×'}</span>
                        <span className={'sr-only'}>{'Close'}</span>
                    </button>
                )}
            </div>
        );
    }

    renderBody(body, bodyClassName) {
        const bodyClassNames = classNames('modal-body', bodyClassName && bodyClassName);

        return <div className={bodyClassNames}>{body}</div>;
    }

    renderFooter(footer, footerClassName) {
        const footerClassNames = classNames('modal-footer', footerClassName && footerClassName);

        return <div className={footerClassNames}>{footer}</div>;
    }

    render() {
        const {
            title,
            subtitle,
            body,
            footer,
            className,
            bodyClassName,
            footerClassName,
            showCloseButton,
            useOverflow,
            bsSize,
        } = this.props;

        const modalClasses = classNames('modal', 'show', className && className);

        const modalDialogClasses = classNames(
            'modal-dialog',
            useOverflow && 'modal-overflow',
            bsSize === Dialog.SIZE_SM && 'modal-sm',
            bsSize === Dialog.SIZE_LG && 'modal-lg',
            bsSize === Dialog.SIZE_XL && 'modal-xl',
            bsSize === Dialog.SIZE_FULL && 'modal-full',
            bsSize === Dialog.SIZE_FULL_SCREEN && 'modal-fullscreen'
        );

        const backdropClasses = classNames('modal-backdrop');

        return ReactDOM.createPortal(
            <CSSTransition
                in={this.state.open}
                timeout={200}
                classNames={'modal'}
                unmountOnExit
                onEntered={this.handleEntered}
            >
                <div className={modalClasses} tabIndex={'-1'} role={'dialog'}>
                    <div
                        className={modalDialogClasses}
                        role={'document'}
                        ref={node => {
                            this.dialog = node;
                        }}
                    >
                        <div className={'modal-content'}>
                            {title && this.renderHeader(title, subtitle, showCloseButton)}
                            {body && this.renderBody(body, bodyClassName)}
                            {footer && this.renderFooter(footer, footerClassName)}
                        </div>
                    </div>
                    <div className={backdropClasses}></div>
                </div>
            </CSSTransition>,
            getOrCreatePortalRoot()
        );
    }
}

Dialog.SIZE_SM = 'sm';
Dialog.SIZE_LG = 'lg';
Dialog.SIZE_XL = 'xl';
Dialog.SIZE_FULL = 'full';
Dialog.SIZE_FULL_SCREEN = 'fullscreen';

Dialog.defaultProps = {
    show: false,
    onHide: () => {},
    onEsc: () => {},
    className: '',
    showCloseButton: true,
    disableEsc: false,
    useOverflow: false,
};

Dialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    body: PropTypes.node,
    footer: PropTypes.node,
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    onHide: PropTypes.func,
    onEsc: PropTypes.func,
    showCloseButton: PropTypes.bool,
    bsSize: PropTypes.oneOf([
        Dialog.SIZE_SM,
        Dialog.SIZE_LG,
        Dialog.SIZE_XL,
        Dialog.SIZE_FULL,
        Dialog.SIZE_FULL_SCREEN,
    ]),
    disableEsc: PropTypes.bool,
    useOverflow: PropTypes.bool,
};

export default Dialog;
