import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ownerDocument from '../../utils/ownerDocument';
import addEventListener from '../../utils/addEventListener';
import classNames from 'classnames';

class Resizer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDrag: false,
            startPos: {
                x: 0,
                y: 0,
            },
        };

        this.handleMove = this.handleMove.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleEnd = this.handleEnd.bind(this);

        this.listeners = () => {
            const doc = ownerDocument(this);

            const mouseUpListener = addEventListener(doc, 'mouseup', this.handleEnd);
            const mouseMoveListener = addEventListener(doc, 'mousemove', this.handleMove);

            const touchEndListener = addEventListener(doc, 'touchend', this.handleEnd);
            const touchMoveListener = addEventListener(doc, 'touchmove', this.handleMove);

            return {
                remove() {
                    mouseUpListener.remove();
                    mouseMoveListener.remove();
                    touchEndListener.remove();
                    touchMoveListener.remove();
                },
            };
        };
    }

    componentWillUnmount() {
        this.removeListeners();
    }

    isTouch(event) {
        return (event.type === 'touchstart') || (event.type === 'touchmove');
    }

    getClientX(event) {
        return this.isTouch(event) ? event.touches[0].clientX : event.clientX;
    }

    getClientY(event) {
        return this.isTouch(event) ? event.touches[0].clientY : event.clientY;
    }

    handleMove(event) {
        event.preventDefault();

        if (!this.state.isDrag) {
            return;
        }

        const { direction, onResize } = this.props;

        if (direction === Resizer.HORIZONTAL) {
            const diff = this.state.startPos.x - this.getClientX(event);

            if (diff !== 0) {
                onResize(diff);
            }
        }

        if (direction === Resizer.VERTICAL) {
            const diff = this.state.startPos.y - this.getClientY(event);
            if (diff !== 0) {
                onResize(diff);
            }
        }

        this.updateStartPosition(event);
    }

    handleStart(event) {
        event.preventDefault();

        this.setState({
            isDrag: true,
            startPos: this.getStartPos(event),
        });

        this.props.onResizeStart(event);

        this.events = this.listeners();
    }

    handleEnd(event) {
        this.setState({
            isDrag: false,
        });

        this.props.onResizeEnd(event);
        this.removeListeners();
    }

    removeListeners() {
        if (this.events) {
            this.events.remove();
        }
    }

    updateStartPosition(event) {
        this.setState({
            startPos: this.getStartPos(event),
        });
    }

    getStartPos(event) {
        return {
            x: this.getClientX(event),
            y: this.getClientY(event),
        };
    }

    render() {
        const { className, direction, position, children } = this.props;

        const wrapperClasses = classNames(
            'Resizer',
            direction === Resizer.HORIZONTAL && 'resize-horizontal',
            direction === Resizer.VERTICAL && 'resize-vertical',
            position === Resizer.LEFT && 'resize-left',
            position === Resizer.RIGHT && 'resize-right',
            position === Resizer.TOP && 'resize-top',
            position === Resizer.BOTTOM && 'resize-bottom',
            className && className
        );

        return (
            <div
                className={wrapperClasses}
                onMouseDown={this.handleStart}
                onMouseMove={this.handleMove}
                onMouseUp={this.handleEnd}
                onTouchStart={this.handleStart}
                onTouchMove={this.handleMove}
                onTouchEnd={this.handleEnd}>
                {children && children}
            </div>
        );
    }
}

Resizer.HORIZONTAL = 'x';
Resizer.VERTICAL = 'y';

Resizer.LEFT = 'left';
Resizer.RIGHT = 'right';
Resizer.TOP = 'top';
Resizer.BOTTOM = 'bottom';

Resizer.defaultProps = {
    className: '',
    direction: Resizer.HORIZONTAL,
    position: Resizer.RIGHT,
    onResizeStart: () => {},
    onResize: () => {},
    onResizeEnd: () => {},
};

Resizer.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.oneOf([
        Resizer.HORIZONTAL,
        Resizer.VERTICAL,
    ]),
    position: PropTypes.oneOf([
        Resizer.LEFT,
        Resizer.RIGHT,
        Resizer.TOP,
        Resizer.BOTTOM,
    ]),
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func.isRequired,
    onResizeEnd: PropTypes.func,
};

export default Resizer;
