import React from 'react';
import PropTypes from 'prop-types';

const DropdownHeader = props => {
    const { icon, label, center } = props;
    return (
        <li className={`dropdown-header ${center ? 'center' : ''}`}>
            <div className={'dropdown-header-line'}>
                <hr />
            </div>
            <span className={'dropdown-header-text'}>
                {icon && <span className={'margin-right-5'}>{icon}</span>}
                {label}
            </span>
            <div className={'dropdown-header-line'}>
                <hr />
            </div>
        </li>
    );
};

DropdownHeader.defaultProps = {
    center: true,
};

DropdownHeader.propTypes = {
    icon: PropTypes.string,
    center: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DropdownHeader;
