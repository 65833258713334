import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Activity = props => {
    const { activity, duration, isOutdated, bsSize, onClick, className } = props;

    if (!activity) {
        return null;
    }

    const icon = Activity.icons[activity];

    const wrapperClassNames = classNames(
        'activity',
        `activity-${activity}`,
        isOutdated && 'opacity-50',
        bsSize && `activity-${bsSize}`,
        className && className
    );

    return (
        <div className={wrapperClassNames} onClick={onClick}>
            <span className={'activity-icon'}>
                <span className={`rioglyph ${icon}`}></span>
            </span>
            {duration && <span className={'activity-text'}>{duration}</span>}
        </div>
    );
};

Activity.AVAILABLE = 'available';
Activity.DRIVING = 'driving';
Activity.RESTING = 'resting';
Activity.WORKING = 'working';

Activity.icons = {
    [Activity.WORKING]: 'rioglyph-status-working',
    [Activity.AVAILABLE]: 'rioglyph-status-available',
    [Activity.DRIVING]: 'rioglyph-status-driving',
    [Activity.RESTING]: 'rioglyph-status-resting',
};

Activity.SIZE_SM = 'sm';
Activity.SIZE_LG = 'lg';
Activity.SIZE_XL = 'xl';

Activity.defaultProps = {
    className: '',
    isOutdated: false,
    onClick: () => {},
};

Activity.propTypes = {
    activity: PropTypes.oneOf([Activity.AVAILABLE, Activity.DRIVING, Activity.RESTING, Activity.WORKING]).isRequired,
    bsSize: PropTypes.oneOf([Activity.SIZE_SM, Activity.SIZE_LG, Activity.SIZE_XL]),
    className: PropTypes.string,
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isOutdated: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default Activity;
