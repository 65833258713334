import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StatsWidgetHeader from './StatsWidgetHeader';
import StatsWidgetBody from './StatsWidgetBody';
import StatsWidgetFooter from './StatsWidgetFooter';

const StatsWidget = props => {
    const { children, hasFilter, onFilterReset, className, ...remainingProps } = props;

    const resetFilterButtonClassNames = classNames(
        'bg-light hover-bg-highlight',
        'display-flex justify-content-center align-items-center',
        'padding-2',
        'rounded-circle',
        'position-absolute top-15 right-15',
        'display-block',
        'height-20 width-20',
        'cursor-pointer'
    );

    const wrapperClasses = classNames('StatsWidget', className && className);

    return (
        <div {...remainingProps} className={wrapperClasses}>
            {hasFilter && (
                <span className={resetFilterButtonClassNames} onClick={onFilterReset}>
                    <span className={'rioglyph rioglyph-revert text-color-white text-size-12'}></span>
                </span>
            )}
            {children}
        </div>
    );
};

StatsWidget.Header = StatsWidgetHeader;
StatsWidget.Body = StatsWidgetBody;
StatsWidget.Footer = StatsWidgetFooter;

StatsWidget.defaultProps = {
    hasFilter: false,
    onFilterReset: () => {},
};

StatsWidget.propTypes = {
    hasFilter: PropTypes.bool,
    onFilterReset: PropTypes.func,
    className: PropTypes.string,
};

export default StatsWidget;
