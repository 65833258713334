import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import head from 'lodash/fp/head';
import isArray from 'lodash/fp/isArray';
import throttle from 'lodash/fp/throttle';

import addEventListener from '../../utils/addEventListener';
import ownerDocument from '../../utils/ownerDocument';
import Resizer from '../resizer/Resizer';
import TreeSidebar from './TreeSidebar';

import TreeCategory from './TreeCategory';

const RESIZE_THROTTLE = 500;

const getSidebarBodyByRef = sidebarRef => head(sidebarRef.getElementsByClassName('AssetTreeBody'));

class AssetTree extends Component {
    constructor(props) {
        super(props);

        const { width, fly } = this.props;

        this.state = {
            width,
            sidebarMode: fly ? AssetTree.MODE_FLY : AssetTree.MODE_FLUID,
        };

        this.handleSelectCategory = this.handleSelectCategory.bind(this);
        this.handleToggleTreeContent = this.handleToggleTreeContent.bind(this);
        this.handleResizeStart = this.handleResizeStart.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleResizeEnd = this.handleResizeEnd.bind(this);

        this.onWindowResize = throttle(RESIZE_THROTTLE, this.onWindowResize.bind(this));
    }

    componentDidMount() {
        const { resizable, width } = this.props;

        this.setState({
            width: resizable ? parseInt(width, 10) : width,
        });

        const doc = ownerDocument(this);
        this.onDocumentKeydownListener = addEventListener(doc, 'keydown', this.handleEscFullscreen);
    }

    componentWillUnmount() {
        if (this.resizeListener) {
            this.resizeListener.remove();
        }
        if (this.onDocumentKeydownListener) {
            this.onDocumentKeydownListener.remove();
        }
    }

    handleSelectCategory(categoryId) {
        const { isOpen, currentCategoryId, onCategoryChange } = this.props;
        onCategoryChange(categoryId);

        if (!isOpen) {
            this.handleToggleTreeContent();
        } else if (isOpen && currentCategoryId === categoryId) {
            this.handleToggleTreeContent();
        }
    }

    handleToggleTreeContent() {
        const { isOpen, onToggleTree } = this.props;
        onToggleTree(!isOpen);
    }

    onWindowResize() {
        this.adaptSidebarMode();
    }

    getWidthInBoundaries(minWidth, maxWidth, width) {
        if (width > 0 && width < minWidth) {
            return minWidth;
        }

        if (width > 0 && width > maxWidth) {
            return maxWidth;
        }

        if (width > 0 && width > maxWidth) {
            return maxWidth;
        }

        return width;
    }

    handleResize(diff) {
        const { maxWidth, minWidth } = this.props;
        const { width } = this.state;

        const halfWindowWidth = window.innerWidth * 0.5;

        const usedMaxWidth = maxWidth || halfWindowWidth;
        const updatedWidth = width - diff;

        const newWidth = this.getWidthInBoundaries(minWidth, usedMaxWidth, updatedWidth);

        // Check for sidebar with if it is half window size. If it was before but the sidebar was resized so it is
        // no longer walf window size, set the sidebar with to hals window size to avoid jumping sidebar to old width
        this.setState({
            width: newWidth,
        });
    }

    handleResizeStart() {
        const body = getSidebarBodyByRef(this.sidebarRef);
        if (body) {
            body.classList.add('pointer-events-none');
        }

        this.setState({ isResize: true });
    }

    handleResizeEnd() {
        const body = getSidebarBodyByRef(this.sidebarRef);
        if (body) {
            body.classList.remove('pointer-events-none');
        }

        this.setState({ isResize: false });
        this.props.onResizeEnd();
    }

    getCurrentCategoryElement(children, currentCategoryId) {
        return isArray(children) ? children.find(child => child.props.id === currentCategoryId) : children;
    }

    renderTreesOffscreen(children, categoryId) {
        return React.Children.map(children, child => {
            const classes = classNames('TreeOffscreenWrapper', categoryId !== child.props.id && 'position-offscreen');
            return <div className={classes}>{child}</div>;
        });
    }

    render() {
        const {
            className,
            resizable,
            maxWidth,
            height,
            bordered,
            currentCategoryId,
            isOpen,
            useOffscreen,
            children,
        } = this.props;
        const { width, isResize } = this.state;

        const classes = classNames(
            'AssetTree',
            className,
            !isOpen && 'closed',
            bordered && 'panel panel-default',
            this.state.sidebarMode === AssetTree.MODE_FLY ? 'fly' : 'fluid'
        );

        const resizeLimitClasses = classNames('AssetTreeResizeLimit', isResize && 'display-block');

        const resizeIndicatorPosition = maxWidth || window.innerWidth * 0.5;
        const resizeLimitStyle = { left: resizeIndicatorPosition };

        const categoryId = currentCategoryId || 0;

        const firstChild = head(children);

        const category = currentCategoryId ? this.getCurrentCategoryElement(children, currentCategoryId) : firstChild;

        const style = {
            width: width,
            height: height,
        };

        return (
            <div className={classes} style={style} ref={node => (this.sidebarRef = node)}>
                <div className={resizeLimitClasses} style={resizeLimitStyle} />
                <div className={'AssetTreeContent'}>
                    <TreeSidebar
                        categories={isArray(children) ? children : [children]}
                        onSelectCategory={this.handleSelectCategory}
                        currentCategoryId={currentCategoryId}
                        onClick={this.handleToggleTreeContent}
                    />
                    <div className={'AssetTreeBody'}>
                        {useOffscreen ? this.renderTreesOffscreen(children, categoryId) : category}
                    </div>
                </div>
                {resizable && isOpen && (
                    <Resizer
                        onResizeStart={this.handleResizeStart}
                        onResize={this.handleResize}
                        onResizeEnd={this.handleResizeEnd}
                        direction={Resizer.HORIZONTAL}
                        position={Resizer.RIGHT}
                    />
                )}
            </div>
        );
    }
}

AssetTree.displayName = 'AssetTree';

AssetTree.MODE_FLY = 'fly';
AssetTree.MODE_FLUID = 'fluid';

AssetTree.defaultProps = {
    width: 350,
    minWidth: 100,
    maxWidth: 0,
    resizable: true,
    disableEsc: false,
    bordered: false,
    fly: false,
    isOpen: true,
    onToggleTree: () => {},
    children: [],
    onCategoryChange: () => {},
    onResizeEnd: () => {},
    useOffscreen: false,
};

AssetTree.propTypes = {
    fly: PropTypes.bool,
    resizable: PropTypes.bool,
    bordered: PropTypes.bool,
    // When sidebar is resizable it will take the provided width in px only
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    height: PropTypes.number,
    isOpen: PropTypes.bool,
    onToggleTree: PropTypes.func,
    currentCategoryId: PropTypes.string.isRequired,
    onCategoryChange: PropTypes.func,
    className: PropTypes.string,
    onResizeEnd: PropTypes.func,
    useOffscreen: PropTypes.bool,
    children: (props, propName, componentName) => {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function(child) {
            if (child.type !== TreeCategory) {
                error = new Error(`\`${componentName}\` children should be of type \`TreeCategory\`.`);
            }
        });
        return error;
    },
};

export default AssetTree;
