import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import classname from 'classnames';

export default class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.handleToggleKeyDown = this.handleToggleKeyDown.bind(this);
    }

    getInput() {
        return ReactDom.findDOMNode(this).children[0];
    }

    handleToggleKeyDown(event) {
        switch (event.keyCode) {
            case 32:
                // toggle on space
                this.toggle(event);
                break;
            case 13:
                // open on enter
                this.toggle(event);
                break;
            default:
                break;
        }
    }

    toggle(event) {
        event.preventDefault();
        const { disabled, checked, onClick, onChange } = this.props;

        if (disabled) {
            return;
        }

        // Controlled case
        if (checked !== null && checked !== undefined) {
            onChange(event);
            return;
        }

        const input = this.getInput();
        input.checked = !input.checked;

        // For testing capabilities
        this.checked = input.checked;

        onClick(event);
    }

    renderRadioText(text) {
        return (
            <span className={'radio-text'}>
                <span>{text}</span>
            </span>
        );
    }

    renderRadioIcon(icon, iconSize, iconLabelPosition, text) {
        const iconStyles = {
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            fontSize: `${iconSize}px`,
            lineHeight: `${iconSize}px`,
        };

        return (
            <span className={`radio-icon label-${iconLabelPosition}`}>
                <span className={`rioglyph ${icon}`} style={iconStyles} />
                <span className={'radio-label'}>{text}</span>
            </span>
        );
    }

    render() {
        const {
            checked,
            defaultChecked,
            disabled,
            inline,
            onClick,
            onChange,
            tabIndex,
            label,
            children,
            right,
            inputRef,
            className,
            name,
            value,
            icon,
            iconSize,
            iconLabelPosition,
        } = this.props;

        const text = label || children;
        const labelClassnames = classname('radio', inline && 'radio-inline', className);
        const inputClassnames = classname(right && 'icon-right', className);

        return (
            <label className={labelClassnames} tabIndex={tabIndex} onKeyDown={this.handleToggleKeyDown}>
                <input
                    type={'radio'}
                    defaultChecked={defaultChecked}
                    checked={checked}
                    disabled={disabled}
                    className={inputClassnames}
                    ref={inputRef}
                    onClick={onClick}
                    onChange={onChange}
                    name={name}
                    value={value}
                />
                {icon ? this.renderRadioIcon(icon, iconSize, iconLabelPosition, text) : this.renderRadioText(text)}
            </label>
        );
    }
}

RadioButton.ICON_LABEL_VERTICAL = 'vertical';
RadioButton.ICON_LABEL_HORIZONTAL = 'horizontal';

RadioButton.defaultProps = {
    inline: false,
    disabled: false,
    right: false,
    tabIndex: 0,
    icon: '',
    iconSize: 16,
    iconLabelPosition: RadioButton.ICON_LABEL_VERTICAL,
    onClick: () => {},
};

RadioButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconLabelPosition: PropTypes.oneOf([RadioButton.ICON_LABEL_VERTICAL, RadioButton.ICON_LABEL_HORIZONTAL]),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inline: PropTypes.bool,
    right: PropTypes.bool,
    inputRef: PropTypes.func,
    tabIndex: PropTypes.number,
};
