import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Overlay, Popover } from 'react-bootstrap';

export const ActionBarOverlay = ({ children, id, title, onClick, width, popoverClassName, ...props }) => {
    const popoverWidth = classNames(width && `width-${width} max-width-100vw`, popoverClassName);

    return (
        <Overlay {...props} onHide={() => {}}>
            <Popover id={id} title={title} onClick={onClick} className={popoverWidth}>
                {children}
            </Popover>
        </Overlay>
    );
};

ActionBarOverlay.defaultProps = {
    placement: 'bottom',
    rootClose: true,
    width: 250,
};

ActionBarOverlay.propTypes = {
    id: PropTypes.string.isRequired,
    placement: PropTypes.string,
    rootClose: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onHide: PropTypes.func,
    width: PropTypes.oneOf([100, 150, 200, 250, 300, 350, 400, 450, 500]),
    popoverClassName: PropTypes.string,
};

export default ActionBarOverlay;
