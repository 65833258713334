import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import css from 'dom-helpers/style';
import classNames from 'classnames';
import Transition, { ENTERED, ENTERING, EXITED, EXITING } from 'react-transition-group/Transition';

const collapseStyles = {
    [EXITED]: 'collapse',
    [EXITING]: 'collapsing',
    [ENTERING]: 'collapsing',
    [ENTERED]: 'collapse in',
};

const MARGINS = {
    height: ['marginTop', 'marginBottom'],
};

const INITIAL_HEIGHT = '0';

export default class Collapse extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.handleEnter = this.handleEnter.bind(this);
        this.handleEntering = this.handleEntering.bind(this);
        this.handleEntered = this.handleEntered.bind(this);
        this.handleExit = this.handleExit.bind(this);
        this.handleExiting = this.handleExiting.bind(this);
        this.handleExited = this.handleExited.bind(this);
    }

    componentDidMount() {
        if (!this.props.in) {
            // When the component is collapsed on mount, set initial height to 0
            this.setState(() => ({
                transitionState: EXITED,
                height: INITIAL_HEIGHT,
            }));
        } else {
            // When the component is expanded on mount, set initial height to 'auto'
            this.setState(() => ({
                transitionState: ENTERED,
                height: 'auto',
            }));
        }
    }

    componentWillReceiveProps(nextProps) {
        // As the height is set to "auto" in the final opening step, we need to
        // set the height before we start the closing animation as "height: auto" cannot be animated
        if (this.props.in && !nextProps.in) {
            this.setState({
                height: this.getFullHeight(ReactDOM.findDOMNode(this)),
            });
        }
    }

    getFullHeight(element) {
        const value = element.offsetHeight;
        const margins = MARGINS['height'];

        if (!value) {
            return INITIAL_HEIGHT;
        }

        const fullHeight = (
            value +
            parseInt(css(element, margins[0]), 10) +
            parseInt(css(element, margins[1]), 10)
        );

        return fullHeight;
    }

    /* -- Expanding -- */
    handleEnter() {
        this.setState(() => ({
            transitionState: ENTERED,
            height: INITIAL_HEIGHT,
        }), this.props.onEnter());
    }

    handleEntering(element) {
        this.setState(() => ({
            transitionState: ENTERING,
            height: element.scrollHeight,
        }), this.props.onEntering());
    }

    handleEntered() {
        this.setState(() => ({
            transitionState: ENTERED,
            height: 'auto',
        }), this.props.onEntered());
    }

    /* -- Collapsing -- */
    handleExit(element) {
        this.setState(() => ({
            transitionState: EXITING,
            height: this.getFullHeight(element),
        }), this.props.onExit());
    }

    handleExiting() {
        this.setState(() => ({
            transitionState: EXITING,
            height: INITIAL_HEIGHT,
        }), this.props.onExiting());
    }

    handleExited() {
        this.setState(() => ({
            transitionState: EXITED,
            height: INITIAL_HEIGHT,
        }), this.props.onExited());
    }

    render() {
        const { children, ...props } = this.props;

        return (
            <Transition
                ref={(node => (this.refTransition = node))}
                {...props}
                onEnter={this.handleEnter}
                onEntering={this.handleEntering}
                onEntered={this.handleEntered}
                onExit={this.handleExit}
                onExiting={this.handleExiting}
                onExited={this.handleExited}>
                {React.cloneElement(children, {
                    style: { height: this.state.height },
                    className: classNames(
                        children.props.className,
                        collapseStyles[this.state.transitionState]
                    ),
                })}
            </Transition>
        );
    }
}

Collapse.defaultProps = {
    in: false,
    timeout: 300,
    mountOnEnter: false,
    unmountOnExit: false,
    appear: false,
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {},
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
};

Collapse.propTypes = {
    // Show the component; triggers the expand or collapse animation
    in: PropTypes.bool.isRequired,
    // Wait until the first "enter" transition to mount the component (add it to the DOM)
    mountOnEnter: PropTypes.bool,
    // Unmount the component (remove it from the DOM) when it is collapsed
    unmountOnExit: PropTypes.bool,
    // Run the expand animation when the component mounts, if it is initially shown
    appear: PropTypes.bool,
    // Duration of the collapse animation in milliseconds, to ensure that finishing callbacks
    // are fired even if the original browser transition end events are canceled
    timeout: PropTypes.number,
    // Callback fired before the component expands
    onEnter: PropTypes.func,
    // Callback fired after the component starts to expand
    onEntering: PropTypes.func,
    // Callback fired after the component has expanded
    onEntered: PropTypes.func,
    // Callback fired before the component collapses
    onExit: PropTypes.func,
    // Callback fired after the component starts to collapse
    onExiting: PropTypes.func,
    // Callback fired after the component has collapsed
    onExited: PropTypes.func,
};
