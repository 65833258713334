import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from '../slider/Slider';

export class TableSettingsColumnDetails extends Component {

    constructor(props) {
        super(props);

        this.handleColumnWidthChange = this.handleColumnWidthChange.bind(this);
        this.handleWidthInputChange = this.handleWidthInputChange.bind(this);
        this.handleResetColumnWidth = this.handleResetColumnWidth.bind(this);
    }

    handleWidthInputChange(event) {
        const parsedValue = event.target.value ? parseInt(event.target.value, 10) : 0;
        const max = this.props.maxWidth || this.props.maxColumnWidth;
        const value = Math.min(parsedValue, max);
        this.props.onColumnWidthChange(this.props.column, value);
    }

    handleColumnWidthChange(value) {
        this.props.onColumnWidthChange(this.props.column, value);
    }

    handleResetColumnWidth() {
        this.props.onResetColumnWidth(this.props.column);
    }

    render() {
        const {
            width = 0,
            defaultWidth = 0,
            maxWidth,
            maxColumnWidth,
            //alias,
        } = this.props;

        const resetWidthButtonClassNames = classNames(
            'btn',
            'btn-muted',
            'btn-icon-only',
            'btn-sm',
            'margin-left-10',
            (width === defaultWidth) && 'disabled'
        );

        return (
            <div className={'table-settings-item-body'}>
                <Slider
                    className='margin-bottom-0'
                    value={width}
                    minValue={0}
                    maxValue={maxWidth || maxColumnWidth}
                    step={1}
                    onChange={this.handleColumnWidthChange}
                />
                <div className={resetWidthButtonClassNames} role='button' onClick={this.handleResetColumnWidth}>
                    <span className={'rioglyph rioglyph-revert'}></span>
                </div>
                <div className={'column-width-input'}>
                    <div className={'input-group'}>
                        <input
                            className={'form-control text-right padding-right-5 no-controls'}
                            type={'number'}
                            value={width || ''}
                            min={0}
                            max={maxWidth || maxColumnWidth}
                            onChange={this.handleWidthInputChange} />
                        <div className={'input-group-addon'}>{'px'}</div>
                    </div>
                </div>
            </div>
        );
    }
}

TableSettingsColumnDetails.defaultProps = {
    //alias: '',
    onColumnWidthChange: () => undefined,
    onResetColumnWidth: () => undefined,
};

TableSettingsColumnDetails.propTypes = {
    column: PropTypes.string.isRequired,
    width: PropTypes.number,
    defaultWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    maxColumnWidth: PropTypes.number.isRequired,
    //alias: PropTypes.string,
    onColumnWidthChange: PropTypes.func,
    onResetColumnWidth: PropTypes.func,
};
