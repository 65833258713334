import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

const InfoDialog = (props) => {
    const {
        show,
        content,
        onClose,
        className,
        bsSize,
        disableEsc,
    } = props;

    const dialogClassName = `${className ? className : ''} info-dialog`;

    return (
        <Dialog
            show={show}
            body={content}
            onHide={onClose}
            className={dialogClassName}
            bsSize={bsSize}
            disableEsc={disableEsc}
        />
    );
};

InfoDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    content: PropTypes.node,
    onClose: PropTypes.func,
    className: PropTypes.string,
    bsSize: PropTypes.string,
    disableEsc: PropTypes.bool,
};

export default InfoDialog;
