import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

/*
 * Confirmation dialog component to confirm or cancel an action
 */
const ConfirmationDialog = (props) => {
    const {
        show,
        title,
        subtitle,
        content,
        onClickCancel,
        cancelButtonText,
        onClickConfirm,
        confirmButtonText,
        disableConfirm,
        bsSize,
    } = props;

    const footer = (
        <div>
            <button type='button' className='CancelButton btn btn-default' onClick={onClickCancel}>
                {cancelButtonText}
            </button>
            <button type='button' className='ConfirmationButton btn btn-primary'
                onClick={onClickConfirm} disabled={disableConfirm}>
                {confirmButtonText}
            </button>
        </div>
    );

    return (
        <Dialog
            show={show}
            title={title}
            subtitle={subtitle}
            body={content}
            footer={footer}
            onHide={onClickCancel}
            showCloseButton={false}
            bsSize={bsSize}
            className='confirmation-dialog'
            disableEsc
            {...props}
        />
    );
};

ConfirmationDialog.defaultProps = {
    show: false,
    onClickCancel: () => {},
    onClickConfirm: () => {},
};

ConfirmationDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    content: PropTypes.node,
    onClickCancel: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    cancelButtonText: PropTypes.node.isRequired,
    confirmButtonText: PropTypes.node.isRequired,
    disableConfirm: PropTypes.bool,
    bsSize: PropTypes.string,
};

export default ConfirmationDialog;
