import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TagList from '../tag/TagList';
import Tag from '../tag/Tag';

const TagManagerItemList = props => {
    const { tags, onRemoveFromTagList } = props;

    return (
        <TagList inline={false}>
            {tags.map(tag => {
                const tagClasses = classNames(
                    tag.toAdd && 'border-color-success text-color-success',
                    tag.toRemove && 'border-color-danger text-color-danger'
                );
                return (
                    <Tag
                        key={tag.label}
                        className={tagClasses}
                        deletable
                        round={false}
                        onClick={() => onRemoveFromTagList(tag)}
                    >
                        {tag.label}
                    </Tag>
                );
            })}
        </TagList>
    );
};

TagManagerItemList.defaultProps = {};

TagManagerItemList.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            toAdd: PropTypes.bool,
            toRemove: PropTypes.bool,
        })
    ),
    onRemoveFromTagList: PropTypes.func,
};

export default TagManagerItemList;
