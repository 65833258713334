import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spinner from './Spinner';

const SpinnerInfoBox = (props) => {
    const { text, isInverse, isFullSized, isFullScreen } = props;

    const spinnerInfoBoxClasses = classNames(
        'spinnerInfoBox',
        isFullSized && 'fullsized',
        isFullScreen && 'fullscreen',
        isInverse && 'inverse'
    );

    return (
        <div className={spinnerInfoBoxClasses}>
            <div className='spinnerIcon'>
                <Spinner isInverse={isInverse}/>
            </div>
            <div className='spinnerText'>
                {text}
            </div>
        </div>
    );
};

SpinnerInfoBox.defaultProps = {
    isInverse: false,
    isFullSized: false,
    isFullScreen: false,
};

SpinnerInfoBox.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    isInverse: PropTypes.bool,
    isFullSized: PropTypes.bool,
    isFullScreen: PropTypes.bool,
};

export default SpinnerInfoBox;
