import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const FilterListItem = (props) => {
    const { title, titleClass, children, gridWidth, className } = props;

    const classNames = classname(
        'FilterListItem',
        'form-group',
        (gridWidth) && gridWidth,
        (className) && className
    );

    return (
        <div className={classNames}>
            <div className='FilterListItemHeader'>
                <label className={titleClass}>{title}</label>
            </div>
            <div className='FilterListItemContent'>
                {children}
            </div>
        </div>
    );
};

FilterListItem.defaultProps = {
    title: '',
    titleClass: '',
    className: '',
    gridWidth: 'col-md-3',
};

FilterListItem.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
    titleClass: PropTypes.string,
    children: PropTypes.node,
    gridWidth: PropTypes.string,
    className: PropTypes.string,
};

export default FilterListItem;
