import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const TableSettingsColumnButtons = (props) => {
    const {
        column,
        index,
        columnDetails,
        columnOrder,
        openColumnsDetails,
        columnSearchValue,
        onMoveColumn,
        onOpenDetails,
    } = props;

    const navButtonBase = classNames('btn btn-muted btn-icon-only btn-sm');

    const columnUpClasses = classNames(
        navButtonBase,
        (index === 0) && 'disabled'
    );

    const columnDownClasses = classNames(
        navButtonBase,
        (index === columnOrder.length - 1) && 'disabled'
    );

    const toggleIconClassNames = classNames(
        'rioglyph',
        openColumnsDetails[column] ? 'rioglyph-remove' : 'rioglyph-width'
    );

    return (
        <div className='table-settings-item-item-buttons'>
            {!columnSearchValue &&
                <div className={columnUpClasses}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onMoveColumn(column, index - 1, true);
                    }}>
                    <span className={'rioglyph rioglyph-arrow-up'}></span>
                </div>
            }
            {!columnSearchValue &&
                <div className={columnDownClasses}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onMoveColumn(column, index + 1, true);
                    }}>
                    <span className={'rioglyph rioglyph-arrow-down'}></span>
                </div>
            }
            {columnDetails &&
                <div
                    className={navButtonBase}
                    onClick={() => onOpenDetails(column)}>
                    <span className={toggleIconClassNames}></span>
                </div>
            }
        </div>
    );
};

TableSettingsColumnButtons.defaultProps = {
    column: '',
    index: 0,
    columnOrder: [],
    openColumnsDetails: {},
    onMoveColumn: () => undefined,
    onOpenDetails: () => undefined,
};

TableSettingsColumnButtons.propTypes = {
    column: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    openColumnsDetails: PropTypes.object.isRequired,
    columnSearchValue: PropTypes.string,
    onMoveColumn: PropTypes.func,
    onOpenDetails: PropTypes.func,
};
