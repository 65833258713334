import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import compact from 'lodash/fp/compact';

import AutoSuggest from '../autosuggest/AutoSuggest';
import CustomSuggestionItem from './CustomSuggestionItem';
import TagManagerItemList from './TagManagerItemList';

const getCustomSuggestion = (value, placeholder) => ({
    customSuggestion: <CustomSuggestionItem value={value} placeholder={placeholder} />,
    label: value,
    disabled: isEmpty(value),
});

const getSuggestionSeparator = dropdownSeparatorText => ({
    header: true,
    label: dropdownSeparatorText,
});

const cleanupTagState = tag => {
    delete tag.toAdd;
    delete tag.toRemove;
    return tag;
};

const hasTagAlreadySelected = (tags, newSuggestion) => !!tags.find(tag => tag.label === newSuggestion.label);

const getNewSuggestion = suggestion => {
    const newSuggestion = suggestion.customSuggestion ? { label: suggestion.label } : suggestion;
    newSuggestion.toAdd = true;
    return newSuggestion;
};

const buildSuggestions = (useCustomTags, customTagPlaceholder, dropdownSeparatorText) => (value, suggestions) => {
    const cleanedSuggestions = suggestions.map(cleanupTagState);
    return compact([
        useCustomTags && getCustomSuggestion(value, customTagPlaceholder),
        useCustomTags && !isEmpty(cleanedSuggestions) && getSuggestionSeparator(dropdownSeparatorText),
        ...cleanedSuggestions,
    ]);
};

const filterSuggestions = (suggestions, selectedTagNames, value) =>
    suggestions.filter(suggestion => {
        const label = suggestion.label;
        if (!label) {
            return false;
        }
        return (
            label.includes(value) &&
            !selectedTagNames.includes(label) &&
            !suggestion.header &&
            !suggestion.customSuggestion
        );
    });

const TagManager = props => {
    const {
        tagList,
        tagSuggestions,
        placeholder,
        className,
        customTagPlaceholder,
        dropdownSeparatorText,
        onTagListChange,
        useCustomTags,
        noItemMessage,
        ...remainingProps
    } = props;

    const [tags, setTags] = useState(tagList);
    const [inputValue, setInputValue] = useState('');
    const [currentSuggestions, setCurrentSuggestions] = useState();

    const getSuggestions = buildSuggestions(useCustomTags, customTagPlaceholder, dropdownSeparatorText);

    // Update internal lisft of tags when they change from the outside i.e. when they have been saved,
    // additionally, cleanup previos state for adding or removing tags
    useEffect(() => setTags(tagList.map(cleanupTagState)), [tagList]);

    // build suggestion when passed tag suggestions passed change i.e. when a tag got selected and removed from the list
    useEffect(() => setCurrentSuggestions(getSuggestions(inputValue, tagSuggestions)), [tagSuggestions]);

    // build suggestions when input value change as it should filter the list on suggestions
    useEffect(() => {
        const selectedTagNames = tags.map(tag => tag.label);
        const value = inputValue || '';

        const filteredList = filterSuggestions(tagSuggestions, selectedTagNames, value);
        setCurrentSuggestions(getSuggestions(value, filteredList));
    }, [tags, inputValue, tagSuggestions]);

    const updateTags = updatedTags => {
        setTags(updatedTags);
        // Notify outside component
        onTagListChange(updatedTags);
    };

    const handleSuggestionsChange = ({ value }) => setInputValue(value);

    const handleSuggestionSelected = (event, { suggestion }) => {
        setInputValue('');

        const newSuggestion = getNewSuggestion(suggestion);

        if (hasTagAlreadySelected(tags, newSuggestion)) {
            updateTags([...tags]);
            return;
        }

        updateTags([...tags, newSuggestion]);
    };

    const updateTagsWithSelectedTag = (tagItems, selectedTag) => {
        const updatedTags = tagItems.map(tag => {
            if (isEqual(tag.label, selectedTag.label)) {
                // update tag and mark as "to remove"
                if (tag.toRemove) {
                    delete tag.toRemove;
                } else {
                    tag.toRemove = true;
                }
            }
            return tag;
        });
        return updatedTags;
    };

    const handleRemoveFromTagList = selectedTag => {
        if (selectedTag.toAdd) {
            updateTags(tags.filter(tag => !isEqual(tag, selectedTag)));
        } else {
            updateTags(updateTagsWithSelectedTag(tags, selectedTag));
        }
    };

    const inputProps = {
        className: '',
        placeholder: placeholder || 'Start typing ...',
        value: inputValue,
        tabIndex: 1,
    };

    const autosuggestClasses = classNames(!isEmpty(tags) && 'margin-bottom-10');

    return (
        <div {...remainingProps} className={classNames('TagManager', className)}>
            <AutoSuggest
                className={autosuggestClasses}
                inputProps={inputProps}
                suggestions={currentSuggestions}
                onSuggestionSelected={handleSuggestionSelected}
                onSuggestionsFetchRequested={handleSuggestionsChange}
                openOnFocus
                noItemMessage={noItemMessage}
            />
            <TagManagerItemList tags={tags} onRemoveFromTagList={handleRemoveFromTagList} />
        </div>
    );
};

TagManager.defaultProps = {
    useCustomTags: true,
};

TagManager.propTypes = {
    tagList: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        })
    ).isRequired,
    tagSuggestions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
        })
    ).isRequired,
    onTagListChange: PropTypes.func,
    placeholder: PropTypes.string,
    customTagPlaceholder: PropTypes.string,
    dropdownSeparatorText: PropTypes.string,
    useCustomTags: PropTypes.bool,
    noItemMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
};

export default TagManager;
