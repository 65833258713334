import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class DataTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: props.activeKey || this.getFirstTabKey(props.children),
        };

        this.handleSelectTab = this.handleSelectTab.bind(this);
    }

    getFirstTabKey(children = []) {
        const [firstItem] = children;
        return firstItem && firstItem.props.tabKey;
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => ({
            activeKey: nextProps.activeKey,
        }));
    }

    handleSelectTab(event) {
        const tabKey = event.currentTarget.getAttribute('data-tabkey');
        this.props.onSelectTab(tabKey);
    }

    render() {
        const {
            hoverTextColor,
            hoverBgColor,
            activeTextColor,
            activeBgColor,
            children,
            tabContentClassName,
            bordered,
            className: wrapperClassName,
            commonTabContent,
            disableTransition,
        } = this.props;

        const { activeKey } = this.state;

        const wrapperClassNames = classNames('DataTabsContainer', bordered && 'DataTab-bordered', wrapperClassName);

        return (
            <div className={wrapperClassNames}>
                <div className={'DataTabsWrapper'}>
                    <ul className={'DataTabs'} role={'tabList'}>
                        {React.Children.map(children, child => {
                            const { tabKey, className, title } = child.props;
                            const isActive = tabKey === activeKey;

                            const tabClassNames = classNames(
                                'DataTab',
                                'tab',
                                !isActive && `${hoverTextColor} ${hoverBgColor} cursor-pointer`,
                                isActive && `active ${activeTextColor} ${activeBgColor}`,
                                className && className
                            );

                            return (
                                <li
                                    key={tabKey}
                                    className={tabClassNames}
                                    onClick={this.handleSelectTab}
                                    data-tabkey={tabKey}
                                >
                                    {title}
                                    <div className={`arrow bg-highlight-dark ${isActive ? 'in' : ''}`} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className={`tab-content ${tabContentClassName}`} role={'tabpanel'}>
                    {commonTabContent
                        ? commonTabContent
                        : React.Children.map(children, child =>
                              React.cloneElement(child, {
                                  active: child.props.tabKey === activeKey,
                                  disableTransition,
                              })
                          )}
                </div>
            </div>
        );
    }
}

DataTabs.defaultProps = {
    activeKey: '',
    onSelectTab: () => {},
    className: '',
    tabContentClassName: '',
    mountOnEnter: true,
    unmountOnExit: true,
    hoverTextColor: 'hover-text-color-darkest',
    hoverBgColor: 'hover-bg-highlight-decent',
    activeTextColor: 'text-color-white',
    activeBgColor: 'bg-highlight-dark',
    bordered: true,
    disableTransition: false,
    children: [],
};

DataTabs.propTypes = {
    activeKey: PropTypes.string,
    onSelectTab: PropTypes.func.isRequired,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    tabContentClassName: PropTypes.string,
    hoverTextColor: PropTypes.string,
    hoverBgColor: PropTypes.string,
    activeTextColor: PropTypes.string,
    activeBgColor: PropTypes.string,
    bordered: PropTypes.bool,
    disableTransition: PropTypes.bool,
    className: PropTypes.string,
};
