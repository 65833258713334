import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

export class AppMenuDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleDropdownClick() {
        this.setState({
            open: !this.state.open,
        });
    }

    handleClickOutside() {
        if (this.state.open) {
            this.setState({
                open: false,
            });
        }
    }

    renderToggle() {
        const { title, caret } = this.props;
        return (
            <a className={`ModuleNavigation-dropdown dropdown-toggle ${caret ? '' : 'inactive'}`} role='button'
                id='basic-nav-dropdown'>
                <span>{title}</span>
                {caret && <span className='caret'/>}
            </a>
        );
    }

    renderMenu() {
        if (!this.props.children || !this.props.children.length) {
            return null;
        }

        return (
            <ul role='menu' className='dropdown-menu'>
                {this.props.children}
            </ul>
        );
    }

    render() {
        const toggle = this.renderToggle();
        const menu = this.renderMenu();
        const openClass = (this.props.children && this.props.children.length) ? 'open' : '';
        return (
            <li className={`dropdown ${this.state.open ? openClass : ''}`} onClick={() => this.handleDropdownClick()}>
                {toggle}
                {menu}
            </li>
        );
    }
}

export default onClickOutside(AppMenuDropdown);

AppMenuDropdown.defaultProps = {
    caret: false,
};

AppMenuDropdown.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]).isRequired,
    caret: PropTypes.bool,
};
