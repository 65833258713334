import React from 'react';
import CustomState from './CustomState';
import { baseStatePropTypes } from './baseStatePropTypes';

const NotBookedState = (props) => <CustomState {...props} />;

NotBookedState.defaultProps = {
    icons: [{
        name: 'rioglyph-parcel',
        className: 'text-size-200pct',
    }, {
        name: 'rioglyph-arrow-right',
        className: 'text-size-100pct margin-left-10 margin-right-5',
    }, {
        name: 'rioglyph-shopping-cart',
        className: 'text-size-200pct',
    }],
};

NotBookedState.propTypes = baseStatePropTypes;

export default NotBookedState;
