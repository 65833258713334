import orderBy from 'lodash/orderBy';
import { orderBy as naturalOrderBy } from 'natural-orderby';

export const SortDirection = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
};

const toLowerCaseProp = (element, prop) => {
    if (typeof element[prop] === 'string') {
        return (element[prop]).toLowerCase();
    }

    return element[prop];
};

export function sortByProperty(array, property, sortDirection = SortDirection.ASCENDING, isCaseSensitiv) {
    if (!isCaseSensitiv) {
        return orderBy(array, (element) => toLowerCaseProp(element, property), sortDirection);
    }
    return orderBy(array, property, sortDirection);
}

export function naturalSortByProperty(array, property, sortDirection = SortDirection.ASCENDING, isCaseSensitiv) {
    if (!isCaseSensitiv) {
        return naturalOrderBy(array, (element) => toLowerCaseProp(element, property), sortDirection);
    }
    return naturalOrderBy(array, property, sortDirection);
}
