import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NotificationContainer } from 'react-notifications';

const NotificationsContainer = ({ stacked }) => {
    const wrapperClasses = classNames('notification-container-wrapper', stacked && 'stacked');
    return (
        <div className={wrapperClasses}>
            <NotificationContainer />
        </div>
    );
};

NotificationsContainer.defaultProps = {
    stacked: false,
};

NotificationsContainer.propTypes = {
    stacked: PropTypes.bool,
};

export default NotificationsContainer;
