import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dialog from './Dialog';

/*
 * Component to show a modal popup containing a title, split content and a close handler.
 */
const SplitDialog = props => {
    const {
        show,
        title,
        subtitle,
        leftContent,
        rightContent,
        footer,
        onClose,
        className,
        bodyClassName,
        footerClassName,
        bsSize,
        disableEsc,
        useOverflow,
    } = props;

    const dialogClassName = `split-dialog ${className}`;

    const dividerClasses = classNames(
        'split-divider',
        'position-absolute',
        'padding-bottom-1-xs',
        'padding-right-1-ls',
        'bottom-0',
        'left-0-xs',
        'right-0',
        'top-0-ls',
        'bg-lighter'
    );

    const body = (
        <div className={'split-wrapper display-flex-ls'}>
            <div className={'split-left padding-bottom-10-xs margin-bottom-20-xs position-relative'}>
                {leftContent}
                <div className={dividerClasses}></div>
            </div>
            <div className={'split-right flex-1-1-ls padding-left-20-ls'}>{rightContent}</div>
        </div>
    );

    return (
        <Dialog
            show={show}
            title={title}
            subtitle={subtitle}
            body={body}
            footer={footer}
            onHide={onClose}
            className={dialogClassName}
            bodyClassName={bodyClassName}
            footerClassName={footerClassName}
            showCloseButton={true}
            bsSize={bsSize}
            disableEsc={disableEsc}
            useOverflow={useOverflow}
        />
    );
};

SplitDialog.defaultProps = {
    className: '',
    footer: '',
    useOverflow: true,
};

SplitDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    onClose: PropTypes.func,
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    footer: PropTypes.node,
    bsSize: PropTypes.string,
    disableEsc: PropTypes.bool,
    useOverflow: PropTypes.bool,
};

export default SplitDialog;
