export const SUPPORT_MARKER_CLASS = 'show-support-marker';

export const toggleSupportMarker = () => {
    if (!document || !document.documentElement) {
        return;
    }

    const htmlClassList = document.documentElement.classList;
    if (htmlClassList.contains(SUPPORT_MARKER_CLASS)) {
        htmlClassList.remove(SUPPORT_MARKER_CLASS);
    } else {
        htmlClassList.add(SUPPORT_MARKER_CLASS);
    }
};

export const enableSupportMarker = enabled => {
    if (!document || !document.documentElement) {
        return;
    }

    const htmlClassList = document.documentElement.classList;

    if (enabled) {
        htmlClassList.add(SUPPORT_MARKER_CLASS);
    } else {
        htmlClassList.remove(SUPPORT_MARKER_CLASS);
    }
};
