import React from 'react';
import CustomState from './CustomState';
import { baseStatePropTypes } from './baseStatePropTypes';

const EmptyState = (props) => <CustomState {...props} />;

EmptyState.defaultProps = {
    icon: 'rioglyph-empty',
};

EmptyState.propTypes = baseStatePropTypes;

export default EmptyState;
