import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CustomSuggestionItem = ({ value, placeholder }) => {
    const wrapperClassNames = classNames(
        'display-flex align-items-center',
        'padding-top-5 padding-bottom-5',
        !value && 'text-color-gray'
    );

    const iconClassNames = classNames(
        'margin-left--5 margin-right-5 margin-top--1',
        'rioglyph',
        !value ? 'rioglyph-arrow-up' : 'rioglyph-plus'
    );

    return (
        <span className={wrapperClassNames}>
            <span className={iconClassNames} />
            <span className={'text-italic'}>{value || placeholder}</span>
        </span>
    );
};

CustomSuggestionItem.defaultProps = {};

CustomSuggestionItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default CustomSuggestionItem;
