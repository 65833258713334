import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import ReleaseNotes from '../releaseNotes/ReleaseNotes';

const setReleaseNotesItem = props => {
    window.localStorage.setItem(props.RELEASE_NOTES_ITEM, props.currentVersion);
    props.setShowReleaseNotes(false);
};

const getModuleName = (moduleName, whatsNew) => <span>{moduleName ? moduleName : whatsNew}</span>;

const getBody = props => {
    const { translatedReleaseNotes } = props;
    return <ReleaseNotes releaseNotes={translatedReleaseNotes} />;
};

const getFooter = props => {
    const { closeButtonText } = props;
    return (
        <button
            data-component={'CloseButton'}
            type={'button'}
            className={'btn btn-default'}
            onClick={() => setReleaseNotesItem(props)}
        >
            {closeButtonText || 'Close'}
        </button>
    );
};

const ReleaseNotesDialog = props => {
    const { moduleName, whatsNewTitle } = props;

    return (
        <Dialog
            show={props.showReleaseNotes}
            useOverflow={props.useOverflow}
            title={getModuleName(moduleName, whatsNewTitle)}
            subtitle={moduleName && whatsNewTitle}
            body={getBody(props)}
            footer={getFooter(props)}
            onHide={() => setReleaseNotesItem(props)}
        />
    );
};

ReleaseNotesDialog.defaultProps = {
    useOverflow: true,
};

ReleaseNotesDialog.propTypes = {
    showReleaseNotes: PropTypes.bool.isRequired,
    setShowReleaseNotes: PropTypes.func.isRequired,
    whatsNewTitle: PropTypes.string,
    moduleName: PropTypes.string,
    closeButtonText: PropTypes.string,
    translatedReleaseNotes: PropTypes.object,
    currentVersion: PropTypes.string.isRequired,
    RELEASE_NOTES_ITEM: PropTypes.string.isRequired,
    useOverflow: PropTypes.bool,
};

export default ReleaseNotesDialog;
