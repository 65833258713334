import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import compact from 'lodash/fp/compact';
import mapValues from 'lodash/fp/mapValues';
import some from 'lodash/fp/some';

import Dialog from '../dialog/Dialog';
import ClearableInput from '../clearableInput/ClearableInput';
import Checkbox from '../checkbox/Checkbox';
import Collapse from '../collapse/Collapse';
import { TableSettingsColumnDetails } from './TableSettingsColumnDetails';
import { TableSettingsDialogFooter } from './TableSettingsDialogFooter';
import { TableSettingsColumnButtons } from './TableSettingsColumnButtons';
import getOffsetTopWholeScreen from '../../utils/getOffsetTopWholeScreen';

const STOPPING_NODES = ['A', 'BUTTON', 'INPUT', 'LABEL'];
const STOPPING_CLASSES = ['btn'];

const DEFAULT_COLUMN_WIDTH = 0;
const MAX_COLUMN_WIDTH = 1000;

const itemClassName = 'table-settings-item';

const preventDefault = event => event.preventDefault();

const getLowerIndex = index => (index < 0) ? 0 : index;
const getHigherIndex = (index, columnOrder) => (index > columnOrder.length - 1) ? columnOrder.length - 1 : index;

class TableSettingsDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            columnSearchValue: props.columnSearchValue,
            columnOrder: props.columnOrder || props.defaultColumnOrder,
            hiddenColumns: props.hiddenColumns || props.defaultHiddenColumns,
            draggingColumn: props.columnOrder ? !isEqual(props.columnOrder, props.hiddenColumns) : false,
            draggingPageY: false,
            draggingOffsetHeight: false,
            hasChanged: false,
            movedColumn: false,
            columnsDetails: props.columnsDetails,
            columnLabelStrings: [],
            updateColumnLabelStrings: true,
            openColumnsDetails: {},
        };

        this.moveColumnToIndex = this.moveColumnToIndex.bind(this);
        this.handleResetColumnChanges = this.handleResetColumnChanges.bind(this);
        this.handleCancelResetColumnChanges = this.handleCancelResetColumnChanges.bind(this);
        this.resetAllColumnChanges = this.resetAllColumnChanges.bind(this);
        this.discardColumnChanges = this.discardColumnChanges.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.toggleHideColumn = this.toggleHideColumn.bind(this);
        this.handleDragColumnStart = this.handleDragColumnStart.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.deleteMovedColumn = this.deleteMovedColumn.bind(this);
        this.handleColumnWidthChange = this.handleColumnWidthChange.bind(this);
        this.handleResetColumnWidth = this.handleResetColumnWidth.bind(this);
        this.handleOpenColumnsDetails = this.handleOpenColumnsDetails.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const {
            columnSearchValue,
            columnOrder,
            hiddenColumns,
            columnsDetails,
        } = this.state;

        const hasColumnSearchValueChanged = !isEqual(columnSearchValue, nextProps.columnSearchValue);
        const hasColumnOrderChanged = !isEqual(columnOrder, nextProps.columnOrder);
        const hasHiddenColumnsChanged = !isEqual(hiddenColumns, nextProps.hiddenColumns);
        const hasColumnsDetailsChanged = this.hasColumnsDetailsChanged(columnsDetails);

        if (hasColumnSearchValueChanged ||
            hasColumnOrderChanged ||
            hasHiddenColumnsChanged ||
            hasColumnsDetailsChanged) {
            this.setState({
                columnSearchValue: hasColumnSearchValueChanged ? nextProps.columnSearchValue : columnSearchValue,
                columnOrder: hasColumnOrderChanged ? nextProps.columnOrder : columnOrder,
                hiddenColumns: hasHiddenColumnsChanged ? nextProps.hiddenColumns : hiddenColumns,
                columnsDetails: hasColumnsDetailsChanged ? nextProps.columnsDetails : columnsDetails,
                updateColumnLabelStrings: true,
            });
        }
    }

    componentDidMount() {
        const {
            defaultColumnOrder,
            defaultHiddenColumns,
            columnsDetails,
            columnOrder,
            hiddenColumns,
        } = this.props;

        this.setColumnLabelStrings();

        const hasColumnOrderChanged = !isEqual(columnOrder, defaultColumnOrder);
        const hasHiddenColumnsChanged = !isEqual(hiddenColumns, defaultHiddenColumns);
        const hasColumnsDetailsChanged = this.hasColumnsDetailsChanged(columnsDetails);

        if (hasColumnOrderChanged || hasHiddenColumnsChanged || hasColumnsDetailsChanged) {
            this.setState({ hasChanged: true });
        }
    }

    componentDidUpdate() {
        if (this.props.show && this.state.updateColumnLabelStrings) {
            this.setColumnLabelStrings();
        }
    }

    hasColumnsDetailsChanged(columnsDetails) {
        if (isEmpty(columnsDetails)) {
            return false;
        }

        const hasChanged = some(details => {
            const defaultWidth = isFinite(details.defaultWidth) ? details.defaultWidth : DEFAULT_COLUMN_WIDTH;
            return details.width !== defaultWidth;
        })(columnsDetails);

        return hasChanged;
    }

    setColumnLabelStrings() {
        if (!this.contentRef) {
            return;
        }

        // For searching by name we need to get the label from the DOM as it may contain a FormattedMessage
        const labels = this.contentRef.getElementsByClassName('table-settings-item-label');

        const columnStrings = {};
        [...labels].map(label => {
            const key = label.getAttribute('data-key');
            columnStrings[key] = label.textContent.replace(/\r?\n|\r/g, '').toLowerCase();
        });

        this.setState({
            columnLabelStrings: columnStrings,
            updateColumnLabelStrings: false,
        });
    }

    deleteMovedColumn() {
        this.setState({
            movedColumn: false,
        });
    }

    moveColumnToIndex(columnName, newIndex, changeMovedColumn) {
        const newColumnOrder = this.state.columnOrder.filter(name => name !== columnName);
        newColumnOrder.splice(newIndex, 0, columnName);

        this.setState({
            columnOrder: newColumnOrder,
            movedColumn: changeMovedColumn ? columnName : false,
            hasChanged: true,
        });

        if (this.props.immediateChange) {
            this.props.onColumnChange(newColumnOrder, this.state.hiddenColumns);
        }

        window.setTimeout(this.deleteMovedColumn, 500);
    }

    handleResetColumnChanges() {
        this.setState({ isResetAll: true });
    }

    handleCancelResetColumnChanges() {
        this.setState({ isResetAll: false });
    }

    resetColumnsDetails(columnsDetails) {
        return mapValues((columnDetails) => {
            return {
                ...columnDetails,
                width: columnDetails.defaultWidth || DEFAULT_COLUMN_WIDTH,
            };
        })(columnsDetails);
    }

    resetAllColumnChanges() {
        const { defaultColumnOrder, defaultHiddenColumns } = this.props;
        const { columnsDetails } = this.state;

        const defaultColumnsDetails = this.resetColumnsDetails(columnsDetails);

        const newState = {
            columnOrder: defaultColumnOrder,
            hiddenColumns: defaultHiddenColumns,
            columnSearchValue: '',
            hasChanged: false,
            isResetAll: false,
        };

        if (!isEmpty(columnsDetails)) {
            newState.columnsDetails = defaultColumnsDetails;
        }

        this.setState(() => (newState));

        if (this.props.immediateChange) {
            this.props.onSearchChange('');
            this.props.onColumnChange(defaultColumnOrder, defaultHiddenColumns, defaultColumnsDetails);
        }
    }

    discardColumnChanges() {
        this.props.onSearchChange('');
        this.props.onCancel();
        this.props.onHide();
    }

    applyChanges() {
        const { columnOrder, hiddenColumns, columnsDetails } = this.state;

        this.setState({
            columnSearchValue: '',
        });

        this.props.onSearchChange('');
        this.props.onColumnChange(columnOrder, hiddenColumns, columnsDetails);
        this.props.onApply(columnOrder, hiddenColumns, columnsDetails);
        this.props.onHide();
    }

    toggleHideColumn(column) {
        const { hiddenColumns } = this.state;

        const isHidden = hiddenColumns.includes(column);
        const newHiddenColumns = isHidden ?
            hiddenColumns.filter(name => name !== column) :
            [...hiddenColumns, column];

        this.setState(() => ({
            hiddenColumns: newHiddenColumns,
            hasChanged: true,
        }));

        if (this.props.immediateChange) {
            this.props.onColumnChange(this.state.columnOrder, newHiddenColumns);
        }
    }

    handleDragColumnStart(column, event) {
        // disable D&D when search value is entered as the column order is not preserved
        if (this.state.columnSearchValue) {
            return;
        }

        const target = event.target;
        const parentNode = target.parentNode;

        const isNodeDraggingRow = target.classList.contains(itemClassName);
        const isParentDraggingRow = parentNode.classList.contains(itemClassName);

        const hasWrongClass = STOPPING_CLASSES.some((className) =>
            target.classList.contains(className) ||
            parentNode.classList.contains(className) &&
            !isParentDraggingRow
        );

        const isParentStoppingNode = !isParentDraggingRow && STOPPING_NODES.includes(parentNode.nodeName);

        const shouldNotExecute =
            STOPPING_NODES.includes(target.nodeName) ||
            isParentStoppingNode ||
            hasWrongClass ||
            event.defaultPrevented;

        event.preventDefault();

        if (!isNodeDraggingRow && shouldNotExecute) {
            return false;
        }

        document.addEventListener('mouseup', this.handleDrop);
        document.addEventListener('mousemove', this.handleDrag);

        this.setState({
            draggingColumn: column,
            draggingPageY: getOffsetTopWholeScreen(event.currentTarget),
            draggingOffsetHeight: event.currentTarget.offsetHeight,
        });
    }

    handleDrag(event) {
        event.preventDefault();

        const {
            draggingOffsetHeight,
            columnOrder,
            draggingColumn,
            draggingPageY,
            columnSearchValue,
        } = this.state;

        // disable D&D when search value is entered as the column order is not preserved
        if (columnSearchValue) {
            return;
        }

        const diff = event.pageY - draggingPageY;

        const oldIndex = columnOrder.indexOf(draggingColumn);

        const canMoveUp = oldIndex > 0;
        const canMoveDown = oldIndex < columnOrder.length - 1;

        if (diff > draggingOffsetHeight && canMoveDown || diff < 0 && canMoveUp) {

            const movingIndexes = Math.floor(diff / draggingOffsetHeight);

            const newIndexRaw = oldIndex + movingIndexes;
            const newIndex = movingIndexes < 0 ?
                getLowerIndex(newIndexRaw) :
                getHigherIndex(newIndexRaw, columnOrder);

            const movingPixels = (newIndex - oldIndex) * draggingOffsetHeight;

            this.setState({ draggingPageY: draggingPageY + movingPixels });
            this.moveColumnToIndex(draggingColumn, newIndex);
        }
    }

    handleDrop(event) {
        event.preventDefault();

        document.removeEventListener('mouseup', this.handleDrop);
        document.removeEventListener('mousemove', this.handleDrag);

        this.setState({
            draggingColumn: false,
            draggingPageY: false,
            draggingOffsetHeight: false,
        });
    }

    handleSearchChange(searchValue) {
        const newSearch = searchValue.toLowerCase();

        this.setState(() => ({
            columnSearchValue: newSearch,
        }), this.props.onSearchChange(newSearch));
    }

    handleColumnWidthChange(column, value) {
        const columnsDetails = { ...this.state.columnsDetails };

        if (columnsDetails[column]) {
            columnsDetails[column].width = value;
        } else {
            columnsDetails[column] = {
                width: value,
                defaultWidth: 0,
                maxWidth: MAX_COLUMN_WIDTH,
            };
        }

        this.setState(() => ({
            columnsDetails,
            hasChanged: true,
        }));

        this.props.onColumnDetailsChange(column, columnsDetails[column]);
    }

    handleResetColumnWidth(column) {
        const columnsDetails = { ...this.state.columnsDetails };
        const updatedColumnDetails = columnsDetails[column];
        updatedColumnDetails.width = updatedColumnDetails.defaultWidth;

        this.setState(() => ({ columnsDetails }));

        this.props.onColumnDetailsChange(column, columnsDetails[column]);
    }

    handleOpenColumnsDetails(columnName) {
        const updatedOpenColumnDetails = { ...this.state.openColumnsDetails };

        if (updatedOpenColumnDetails[columnName]) {
            delete updatedOpenColumnDetails[columnName];
        } else {
            updatedOpenColumnDetails[columnName] = columnName;
        }

        this.setState(() => ({ openColumnsDetails: updatedOpenColumnDetails }));
    }

    isColumnFilteredOut(searchValue, column = '') {
        if (!searchValue) {
            return false;
        }

        const label = this.state.columnLabelStrings[column] || '';
        return !label.includes(searchValue);
    }

    renderColumnListItem(column, index) {
        const { columnLabels, autoLabel, disabledColumns } = this.props;
        const {
            columnOrder,
            draggingColumn,
            hiddenColumns = [],
            movedColumn,
            columnSearchValue,
            columnsDetails,
            openColumnsDetails,
            updateColumnLabelStrings,
        } = this.state;

        // Filter out items which don't match the search value.
        // Note that we need to render all items at least once at the beginning in order to get their DOM lables
        // otherwise the search won't work when initial search value is provided via props.
        if (this.isColumnFilteredOut(columnSearchValue, column) && !updateColumnLabelStrings) {
            return;
        }

        const isDraggingColumn = column === draggingColumn;

        const itemClassNames = classNames(
            itemClassName,
            (movedColumn && column === movedColumn) && 'movedColumn',
            (draggingColumn && !(isDraggingColumn) ||
            movedColumn && !(column === movedColumn)) && 'hover-bg-white',
            isDraggingColumn && 'dragging',
            columnSearchValue && 'no-drag',
            updateColumnLabelStrings && 'opacity-0'
        );

        const columnDetails = columnsDetails[column];

        return (
            <div className={itemClassNames} key={`table-settings-item-${column}`}>
                <div
                    className={'table-settings-item-header'}
                    draggable={isEmpty(columnSearchValue)}
                    onMouseDown={event => this.handleDragColumnStart(column, event)}>
                    <div className={'CheckboxWrapper display-flex align-items-center padding-left-2'}>
                        <Checkbox
                            checked={!hiddenColumns.includes(column)}
                            onClick={event => {
                                this.toggleHideColumn(column);
                                event.stopPropagation();
                            }}
                            disabled={disabledColumns.includes(column)}
                        />
                    </div>
                    <div className={'table-settings-item-label'} data-key={column}>
                        {columnLabels[column]}
                    </div>
                    {columnDetails &&
                        <div className={'column-width-label'}>
                            {columnDetails.width ? `${columnDetails.width}px` : autoLabel}
                        </div>
                    }
                    <TableSettingsColumnButtons
                        column={column}
                        index={index}
                        columnDetails={columnDetails}
                        columnOrder={columnOrder}
                        openColumnsDetails={openColumnsDetails}
                        columnSearchValue={columnSearchValue}
                        onMoveColumn={this.moveColumnToIndex}
                        onOpenDetails={this.handleOpenColumnsDetails}
                    />
                </div>
                {columnDetails &&
                    <Collapse in={!!openColumnsDetails[column]}>
                        <div>
                            <TableSettingsColumnDetails
                                {...columnDetails}
                                column={column}
                                maxColumnWidth={MAX_COLUMN_WIDTH}
                                onColumnWidthChange={this.handleColumnWidthChange}
                                onResetColumnWidth={this.handleResetColumnWidth} />
                        </div>
                    </Collapse>
                }
            </div>
        );
    }

    renderNotFoundMessage(message) {
        return (
            <div className={'text-center text-color-gray'}>
                {message}
            </div>
        );
    }

    renderTableSettingsDialogContent() {
        const { searchPlaceholder, notFoundMessage } = this.props;

        const columnList = compact(this.state.columnOrder.map((column, index) => (
            this.renderColumnListItem(column, index)
        )));

        return (
            <div ref={node => (this.contentRef = node)}>
                <div className={'table-settings-search'}>
                    <div className={'input-group width-100pct'}>
                        <span className={'input-group-addon'}>
                            <span className={'rioglyph rioglyph-search'}></span>
                        </span>
                        <ClearableInput
                            value={this.state.columnSearchValue}
                            onChange={this.handleSearchChange}
                            placeholder={searchPlaceholder} />
                    </div>
                </div>
                <div className={'table-settings-body'}
                    onDrop={this.handleDrop} onDragOver={preventDefault}>
                    {columnList.length ? columnList : this.renderNotFoundMessage(notFoundMessage)}
                </div>
            </div>
        );
    }

    renderTableSettingsDialogFooter() {
        const {
            immediateChange,
            resetButtonText,
            onHide,
            closeButtonText,
            cancelButtonText,
            applyButtonText,
        } = this.props;
        const { hasChanged, isResetAll } = this.state;

        return (
            <TableSettingsDialogFooter
                hasChanged={hasChanged}
                isResetAll={isResetAll}
                immediateChange={immediateChange}
                resetButtonText={resetButtonText}
                onHide={onHide}
                onApplyChanges={this.applyChanges}
                onDiscardChanges={this.discardColumnChanges}
                onResetColumnChanges={this.handleResetColumnChanges}
                onConfirmResetColumnChanges={this.resetAllColumnChanges}
                onCancelResetColumnChanges={this.handleCancelResetColumnChanges}
                closeButtonText={closeButtonText}
                cancelButtonText={cancelButtonText}
                applyButtonText={applyButtonText}
            />
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const dialogClassNames = classNames(
            'TableSettingsDialog',
            this.props.className && this.props.className
        );

        return (
            <Dialog
                show={this.props.show}
                title={this.props.title}
                onHide={this.props.onHide}
                body={this.renderTableSettingsDialogContent()}
                footer={this.renderTableSettingsDialogFooter()}
                className={dialogClassNames}
            />
        );
    }
}

TableSettingsDialog.defaultProps = {
    show: false,
    immediateChange: false,
    columnSearchValue: '',
    onColumnChange: () => {},
    onColumnDetailsChange: () => {},
    onSearchChange: () => {},
    onCancel: () => {},
    onApply: () => {},
    columnLabels: {},
    defaultColumnOrder: [],
    defaultHiddenColumns: [],
    columnOrder: [],
    hiddenColumns: [],
    disabledColumns: [],
    columnsDetails: {},
    notFoundMessage: '',
    autoLabel: '',
};

const columnsDetailsPropTypes = PropTypes.objectOf(PropTypes.shape({
    width: PropTypes.number,
    defaultWidth: PropTypes.number,
    maxWidth: PropTypes.number,
}));

TableSettingsDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    className: PropTypes.string,
    defaultColumnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
    columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    // listed with { columnName: string/node }
    columnLabels: PropTypes.object.isRequired,
    // hide a sorted column will result in an error, so disable at least one important fallback column
    // or the sorted column (fallback column recommended)
    disabledColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnsDetails: columnsDetailsPropTypes,
    defaultColumnDetails: columnsDetailsPropTypes,
    autoLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // TODO Document this prop
    applyButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    closeButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    resetButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onColumnChange: PropTypes.func.isRequired,
    onColumnDetailsChange: PropTypes.func,
    onDiscard: PropTypes.func,
    onApply: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    columnSearchValue: PropTypes.string,
    onSearchChange: PropTypes.func,
    searchPlaceholder: PropTypes.node.isRequired,
    notFoundMessage: PropTypes.string,
    // set if you want to change the table after each change
    immediateChange: PropTypes.bool,
};

export default TableSettingsDialog;
