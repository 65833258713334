import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

class Slider extends Component {

    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.state = {
            value: (props.value) ? props.value : props.minValue,
            changing: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
    }

    handleChange(event) {
        const newValue = parseInt(event.target.value, 10);
        this.setState({
            value: newValue,
            changing: true,
        });

        this.props.onChange(newValue);
    }

    onMouseUp() {
        document.removeEventListener('mouseup', this.onMouseUp);
        document.getElementsByTagName('body')[0].style.cursor = '';

        this.setState({
            changing: false,
        });

        this.props.onDragEnd(this.state.value);
    }

    onMouseDown() {
        this.setState({ changing: true });
        this.listenToMouseUp();
    }

    listenToMouseUp() {
        document.addEventListener('mouseup', this.onMouseUp);
        document.getElementsByTagName('body')[0].style.cursor = 'pointer';
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value >= nextProps.minValue &&
            nextProps.value <= nextProps.maxValue) {

            this.setState({ value: nextProps.value });
        }
    }

    render() {
        const { value, changing } = this.state;
        const { minValue, maxValue, valueLabels, step, className, disabled } = this.props;

        const classNames = classname(
            'range-slider',
            disabled && 'disabled',
            valueLabels && 'show-value-labels',
            className && className
        );

        const renderValue = () => {
            return (
                <div className='range-value'>
                    { value }
                </div>
            );
        };

        const trackWidth = 100 * (value - minValue) / (maxValue - minValue);

        return (
            <div className={classNames}>
                <div className='range-whole-track'/>
                <div className='range-track' style={{ width: `${trackWidth}%` }}>
                    {valueLabels && renderValue()}
                </div>
                <input
                    className={`${(changing) ? 'changing' : ''}`}
                    value={value}
                    min={minValue}
                    max={maxValue}
                    step={step}
                    type='range'
                    onChange={this.handleChange}
                    onMouseDown={this.onMouseDown}
                />
            </div>
        );
    }
}

Slider.defaultProps = {
    step: 1,
    value: 0,
    minValue: 0,
    maxValue: Number.MAX_VALUE,
    valueLabels: false,
    disabled: false,
    onChange: () => {},
    onDragEnd: () => {},
};

Slider.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    valueLabels: PropTypes.bool,
    step: PropTypes.number,
    onChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Slider;
