import PropTypes from 'prop-types';

export default PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    divider: PropTypes.bool,
    header: PropTypes.bool,
    onSelect: PropTypes.func,
}));
