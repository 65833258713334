import { Component } from 'react';
import PropTypes from 'prop-types';

class ImagePreloader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: ImagePreloader.STATUS_PENDING,
        };
    }

    componentDidMount() {
        this.setState({
            image: this.loadImage(this.props),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.src !== nextProps.src) {
            this.setState({
                status: ImagePreloader.STATUS_PENDING,
                image: this.loadImage(nextProps),
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            willUnmount: true,
        });
    }

    loadImage({ src, isAnonymous }) {
        const image = new Image();

        if (isAnonymous) {
            image.crossOrigin = 'Anonymous';
        }

        // pre-load the actual image
        image.src = src;
        image.onload = () => this.handleImageLoaded();
        image.onerror = () => this.handleImageFailed();

        return image;
    }

    handleImageLoaded() {
        if (this.state.willUnmount) {
            return;
        }

        this.setState({
            status: ImagePreloader.STATUS_LOADED,
        });
        this.props.onLoaded(this.state.image);
    }

    handleImageFailed() {
        if (this.state.willUnmount) {
            return;
        }

        this.setState({
            status: ImagePreloader.STATUS_FAILED,
        });
        this.props.onFailed(this.state.image);
    }

    render() {
        return this.props.children(this.state);
    }
}

ImagePreloader.STATUS_PENDING = 'PENDING';
ImagePreloader.STATUS_LOADED = 'LOADED';
ImagePreloader.STATUS_FAILED = 'FAILED';

ImagePreloader.defaultProps = {
    onLoaded: () => {},
    onFailed: () => {},
    isAnonymous: false,
};

ImagePreloader.propTypes = {
    src: PropTypes.string.isRequired,
    onLoaded: PropTypes.func,
    onFailed: PropTypes.func,
    isAnonymous: PropTypes.bool,
};

export default ImagePreloader;
