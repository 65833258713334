import React from 'react';
import toLower from 'lodash/fp/toLower';

export const SortArrowUp = () => (
    <span className={'sort-icon sort-asc'}></span>
);

export const SortArrowDown = () => (
    <span className={'sort-icon sort-desc'}></span>
);

export const SortArrows = ({ direction = '' }) => (
    <span className={`sort-arrows sort-${toLower(direction)}`}></span>
);
