import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import findIndex from 'lodash/fp/findIndex';

function getIndexOfCompareItem(array, compareItem) {
    return findIndex(item => isEqual(item, compareItem))(array);
}

function getSelectedArrays(selected) {
    const selectedArrays = [];
    selected.forEach(item => {
        if (Array.isArray(item)) {
            selectedArrays.push(item);
        }
    });

    return selectedArrays;
}

function getShownIds(compactData, openRows, extendedData, keyField, groupHeadKeyField) {
    const shownIds = [];
    compactData.forEach(item => {
        const entityReference = item[groupHeadKeyField];

        if (getIndexOfCompareItem(openRows, entityReference) !== -1) {
            extendedData[entityReference].forEach(extendedItem => {
                shownIds.push(extendedItem[keyField]);
            });
        }
        shownIds.push(item[keyField]);
    });

    return shownIds;
}

function getDeSelectedItems(selected, selectedOld, compactData, openRows, extendedData, keyField, groupHeadKeyField) {
    const deSelectedIds = selectedOld.filter((item) => getIndexOfCompareItem(selected, item) === -1);

    const fullDeSelected = [];
    const selectedArrays = getSelectedArrays(selected);
    const shownIds = getShownIds(compactData, openRows, extendedData, keyField, groupHeadKeyField);

    deSelectedIds.forEach(id => {
        if (Array.isArray(id)) {
            id.forEach(oneId => fullDeSelected.push(oneId));
            fullDeSelected.push(id);
        } else if (shownIds.indexOf(id) !== -1) {
            selectedArrays.forEach(array => {
                if (array.indexOf(id) !== -1) {
                    fullDeSelected.push(array);
                }
            });

            fullDeSelected.push(id);
        }
    });

    return fullDeSelected;
}

function getCompactArrays(compactData, keyField) {
    return compactData.map((item) => item[keyField]);
}

export function getNewGroupedSelected(
    selected,
    selectedOld,
    compactData,
    openRows,
    extendedData,
    keyField,
    groupHeadKeyField
) {
    if (isEmpty(selected)) {
        return selected;
    }

    const fullSelected = [];
    const deSelected = getDeSelectedItems(
        selected,
        selectedOld,
        compactData,
        extendedData,
        openRows,
        keyField,
        groupHeadKeyField
    );

    selected.forEach((item) => {
        if (getIndexOfCompareItem(deSelected, item) === -1 && getIndexOfCompareItem(fullSelected, item) === -1) {
            if (Array.isArray(item)) {
                item.forEach((id) => {
                    if (fullSelected.indexOf(id) === -1) {
                        fullSelected.push(id);
                    }
                });
            }

            fullSelected.push(item);
        }
    });

    if (isEmpty(deSelected)) {
        getCompactArrays(compactData, keyField).forEach(fileIds => {
            if (
                !fileIds.some(id => fullSelected.indexOf(id) === -1) &&
                getIndexOfCompareItem(fullSelected, fileIds) === -1
            ) {
                fullSelected.push(fileIds);
            }
        });
    }

    return fullSelected;
}
