import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutHeader = ({ className, children }) => {
    const classes = classNames('ApplicationLayoutHeader', className);

    return (
        <div className={classes} id={'ApplicationLayoutHeader'}>
            {children}
        </div>
    );
};

ApplicationLayoutHeader.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayoutHeader;
