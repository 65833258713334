import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '../collapse/Collapse';
import SelectedFilterTag from './SelectedFilterTag';
import classname from 'classnames';

const CollapsibleFilter = (props) => {
    const { showFilters, selectedFilters, children, closeFilter, onDeleteAll, deleteAllCaption, className } = props;

    let selectedFiltersCount = 0;

    const selectedFilterText = selectedFilters.map((activeFilter, index) => {
        if (activeFilter.chosenOptions.length) {
            selectedFiltersCount += activeFilter.chosenOptions.length;
            return (
                <SelectedFilterTag
                    key={`selectedFilter${index}`}
                    keyName={`selectedFilter${index}`}
                    title={activeFilter.title}
                    onDelete={activeFilter.onDelete}
                    labels={activeFilter.chosenOptions} />
            );
        }
    });

    let deleteAllFilters = '';
    if (selectedFiltersCount > 1) {
        deleteAllFilters = (
            <li className='SelectedFilterTag'>
                <div className='removeFilterButton btn btn-link btn-xs' onClick={onDeleteAll}>
                    <span className='rioglyph rioglyph-trash text-size-12'></span>
                    {deleteAllCaption}
                </div>
            </li>
        );
    }

    let selectedFilterBar = '';
    if (selectedFiltersCount > 0) {
        selectedFilterBar = (
            <div className='row SelectedFilterBar form-group'>
                <div className='col-md-12'>
                    <ul className='list-inline'>
                        {selectedFilterText}
                        {deleteAllFilters}
                    </ul>
                </div>
            </div>
        );
    }

    const classNames = classname(
        'CollapsibleFilter',
        (className) && className
    );

    // required 1 empty div in Collapse to animate the well-margin-closing
    return (
        <div className={classNames}>
            <Collapse in={showFilters}>
                <div>
                    <div className='well FilterContainer form-group'>
                        <a onClick={closeFilter}>
                            <span className='rioglyph rioglyph-remove toggle-icon close'></span>
                        </a>
                        <div className='row'>
                            {children}
                        </div>
                    </div>
                </div>
            </Collapse>
            {selectedFilterBar}
        </div>
    );
};

CollapsibleFilter.defaultProps = {
    showFilters: false,
    selectedFilters: [{
        chosenOptions: [],
        onDelete: () => { /* ignore */ },
    }],
    closeFilter: () => { /* ignore */ },
    onDeleteAll: () => { /* ignore */ },
};

CollapsibleFilter.propTypes = {
    showFilters: PropTypes.bool,
    selectedFilters: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node,
            ]),
            chosenOptions: PropTypes.arrayOf(PropTypes.string),
            onDelete: PropTypes.func,
        })
    ),
    closeFilter: PropTypes.func.isRequired,
    onDeleteAll: PropTypes.func,
    deleteAllCaption: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

export default CollapsibleFilter;
