import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/fp/isArray';
import map from 'lodash/fp/map';
import toPairs from 'lodash/fp/toPairs';

const renderList = notes => <ul className={'margin-bottom-0 margin-left-5 padding-left-25'}>{notes}</ul>;

const renderNode = content => <div className={'padding-left-10'}>{content}</div>;

const getNotes = (content, version) => {
    return map(point => {
        return <li key={`${version}-note-${Math.random()}`}>{point}</li>;
    }, content);
};

const renderNotes = (note, version) => {
    const { date, content } = note;
    return (
        <div className={'panel panel-default'} key={`${version}-note`}>
            <div className={'panel-heading'}>
                <div className={'display-flex justify-content-between'}>
                    <span className={'text-size-16 text-medium'}>{`${version}`}</span>
                    <span className={'text-muted text-thin'}>{date}</span>
                </div>
            </div>
            <div className={'panel-body white-space-pre-line'}>
                {isArray(content) ? renderList(getNotes(content, version)) : renderNode(content)}
            </div>
        </div>
    );
};

const getReleases = ({ releaseNotes }) => {
    return map(([key, note]) => renderNotes(note, key), toPairs(releaseNotes));
};

const ReleaseNotes = props => <div>{getReleases(props)}</div>;

ReleaseNotes.defaultProps = {};

ReleaseNotes.propTypes = {
    releaseNotes: PropTypes.object,
};

export default ReleaseNotes;
