import get from 'lodash/fp/get';
import isFinite from 'lodash/fp/isFinite';
import { SortDirection } from './SortUtil';

export const getSortDirShort = sortDir => (sortDir === SortDirection.DESCENDING ? '-' : '+');

export const parseSorting = sortValueShort => {
    const sortDir = sortValueShort.startsWith('-') ? SortDirection.DESCENDING : SortDirection.ASCENDING;

    // Note: due to a bug in qs 6.7.0 the '+' sorting will be parsed as a space by qs even though decoding is disabled
    // therefore, we need to trim the value
    return {
        sortBy: sortValueShort.replace(/^[+-]/, '').trim(),
        sortDir,
    };
};

export function mapColumnsSettingsToStrings({ columnOrder = [], hiddenColumns = [], columnsDetails = {} }) {
    const mappedColumns = columnOrder.map(column => {
        const currentWidth = get('width')(columnsDetails[column]);
        const defaultWidth = get('defaultWidth')(columnsDetails[column]);

        const width = currentWidth !== 0 && currentWidth !== defaultWidth ? `:${currentWidth}` : '';
        const hiddenSign = hiddenColumns.includes(column) ? '-' : '';

        return `${hiddenSign}${column}${width}`;
    });

    return mappedColumns;
}

export function parseColumnsSettingsStrings(settings = [], defaultColumnsDetails) {
    const columns = {
        columnOrder: [],
        hiddenColumns: [],
        columnsDetails: { ...defaultColumnsDetails },
    };

    settings.map(setting => {
        const [column, columnWidth] = setting.split(':');
        const columnKey = column.startsWith('-') ? column.substr(1) : column;

        columns.columnOrder = [...columns.columnOrder, columnKey];

        if (columnWidth) {
            const width = parseInt(columnWidth, 10);
            if (isFinite(width)) {
                columns.columnsDetails[columnKey] = { ...columns.columnsDetails[columnKey], width };
            }
        } else {
            columns.columnsDetails[columnKey] = { ...defaultColumnsDetails[columnKey] };
        }

        if (column.startsWith('-')) {
            columns.hiddenColumns = [...columns.hiddenColumns, columnKey];
        }
    });

    return columns;
}
