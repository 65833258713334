import has from 'lodash/has';

export const BASE_URL = 'https://uikit.developers.rio.cloud/';

// Logs deprecation warnings to console if prop is used.
// use this function in the render method of a component.
export const logDeprecatedWarnings = (
    deprecatedProps = [],
    props,
    componentName,
    removeVersion,
    additionalText
) => {
    deprecatedProps.forEach((deprecatedProp) => {
        // Check whether the deprected prop is used by checking the component props
        if (has(props, deprecatedProp.prop)) {

            /* eslint-disable no-console */
            console.warn([
                (deprecatedProps.propCaption) ? deprecatedProps.propCaption : 'Prop',
                ` "${deprecatedProp.prop}" is deprecated`,
                (componentName) ? ` for "${componentName}"` : '',
                (removeVersion) ? ` and will be removed in v${removeVersion}.` : '',
                (deprecatedProp.replacement) ? ` Please use "${deprecatedProp.replacement}" instead` : '',
                (deprecatedProp.url) ? ` as documented in ${BASE_URL}${deprecatedProp.url}. ` : '.',
                (additionalText) ? ` ${additionalText}` : '',
            ].join(''));
        }
    });
};

export default logDeprecatedWarnings;
