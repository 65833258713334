import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import head from 'lodash/fp/head';

import { ActionBarOverlay } from './ActionBarOverlay';
import { ActionBarItemPopover } from './ActionBarItemPopover';
import { ActionBarItemIcon } from './ActionBarItemIcon';
import Dialog from '../dialog/Dialog';

export class ActionBarItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };

        this.hide = this.hide.bind(this);
    }

    handleClick() {
        this.setState({
            ...this.state,
            show: !this.state.show,
        });
    }

    hide() {
        if (this.props.hidePopoverOnClick) {
            this.setState({
                ...this.state,
                show: false,
            });
        }
    }

    isMobile() {
        const header = head(document.getElementsByClassName('ApplicationHeader'));
        return header && header.offsetWidth < 580;
    }

    renderBodyComponent(component) {
        const { show } = this.state;
        const { id, popoverWidth } = this.props;
        const { title, useOffscreen } = component.props;

        const shouldBeShown = useOffscreen ? true : show;

        const isOffScreenAndHidden = useOffscreen && !show;
        const offscreenClassName = classNames(isOffScreenAndHidden && 'position-offscreen');

        // Render the content inside a dialog only on mobile
        // Note: not nice to call isMobile() all the time but it saves us from registering an onResize handler for now
        if (this.isMobile()) {
            return <Dialog show={show} onHide={this.hide} body={component} title={title} />;
        }

        return (
            <ActionBarOverlay
                id={id}
                title={title}
                show={shouldBeShown}
                target={this}
                container={this}
                onClick={this.hide}
                width={popoverWidth}
                animation
                popoverClassName={offscreenClassName}
            >
                {component}
            </ActionBarOverlay>
        );
    }

    render() {
        const classes = classNames('ActionBarItem', this.props.className);

        const children = React.Children.map(this.props.children, child => {
            if (child.type.displayName === 'ActionBarItemPopover') {
                return this.renderBodyComponent(child);
            }

            // Inject the click handler for the icon component itself and as we have just the
            // child, we need to clone it in order to extend it with new props
            return React.cloneElement(child, { onClick: () => this.handleClick() });
        });

        return <div className={classes}>{children}</div>;
    }
}

ActionBarItem.Icon = ActionBarItemIcon;
ActionBarItem.Popover = ActionBarItemPopover;

ActionBarItem.defaultProps = {
    hidePopoverOnClick: true,
    popoverWidth: 250,
};

ActionBarItem.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    hidePopoverOnClick: PropTypes.bool,
    popoverWidth: PropTypes.oneOf([100, 150, 200, 250, 300, 350, 400, 450, 500]),
};

export default ActionBarItem;
