import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isObject from 'lodash/fp/isObject';
import isEqual from 'lodash/fp/isEqual';

import Checkbox from '../checkbox/Checkbox';

class TreeLeaf extends Component {
    shouldComponentUpdate(nextProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        const { item, parentNodeId, hasMultiselect, isSelected, onActiveItem, onSelect } = this.props;

        const treeNodeClassNames = classNames(
            'TreeLeaf',
            'from-group',
            isSelected && 'active',
            item.className && item.className
        );

        return (
            <div className={treeNodeClassNames} data-key={item.id}>
                {hasMultiselect && (
                    <Checkbox
                        className={'TreeCheckbox'}
                        checked={isSelected}
                        onClick={() => onSelect(item.id, parentNodeId)}
                    />
                )}
                <span className={'TreeLabel'} onClick={() => onActiveItem(item.id, parentNodeId)}>
                    <span className={'TreeLabelName'}>
                        <span className={`rioglyph rioglyph-${item.type}`}></span>
                        {isObject(item.name) ? (
                            <span>
                                <span className={'text-light margin-right-3'}>{item.name.firstName}</span>
                                <span>{item.name.lastName}</span>
                            </span>
                        ) : (
                            <span>{item.name}</span>
                        )}
                    </span>
                </span>
            </div>
        );
    }
}

TreeLeaf.displayName = 'TreeLeaf';

TreeLeaf.defaultProps = {};

TreeLeaf.propTypes = {
    className: PropTypes.string,
};

export default TreeLeaf;
