import React from 'react';

import ChromeIcon from './chrome.svg';
import FirefoxIcon from './firefox.svg';

const BrowserWarningMessageEN = ({ onBrowserSelect }) => (
    <React.Fragment>
        <div className={'text-center padding-right-1pct padding-left-1pct'}>
            <h1 className={'margin-bottom-25 padding-top-25'}>{'You are using an outdated browser'}</h1>
            <p className={'text-size-18 margin-bottom-25'}>
                {'With just under 7 years the '}
                <b>{'Internet Explorer'}</b>
                {' is slowly getting old and can no longer keep up with modern browsers in terms of '}
                <b>{'speed'}</b>
                {', '}
                <b>{'functionality'}</b>
                {' but above all in terms of '}
                <b>{'security'}</b>
                {'.'}
            </p>
            <p className={'text-size-18 margin-bottom-25'}>
                {'We at RIO, have set ourselves the goal of providing you with the '}
                <b>{'best possible software'}</b>
                {' to offer you the '}
                <b>{'best possible user experience'}</b>
                {'. Therefore, we have decided to end the support for the IE11 in the not too distant future.'}
            </p>
            <p className={'text-size-18'}>
                <b>{'Experience RIO from a whole new side with a modern browser:'}</b>
            </p>
        </div>
        <div
            className={`display-flex justify-content-center justify-content-around flex-wrap
                padding-right-2pct-ls padding-left-2pct-ls`}
        >
            <a
                href={'https://www.google.com/chrome/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={'display-block hover-scale-110'}
                onClick={() => onBrowserSelect('chrome')}
            >
                <ChromeIcon />
            </a>
            <a
                href={'https://www.mozilla.org/firefox/new/'}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={'display-block hover-scale-110'}
                onClick={() => onBrowserSelect('firefox')}
            >
                <FirefoxIcon />
            </a>
        </div>
        {/* <div className={'text-center padding-right-2pct padding-left-2pct'}>
            <hr />
            <p>
                {'If you need help to install another browser or have any other question about the change-over, '}
                {'we are always available for you via our '}
                <b>{'hotline +(49) 08 00 / 22 55 07 46'}</b>
                {' and will be happy to help you.'}
            </p>
        </div> */}
    </React.Fragment>
);

export default BrowserWarningMessageEN;
